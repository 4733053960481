import React from "react"
import { Link } from "react-router-dom"
import TagChip from '../Shared/TagChip'

import { capitalize } from '../../Helpers/TextHelper'

const ProfileTableRow = ({ row }) => {
    let methodTags: string[] = []
    if (row.methods) {
        let methodsArr = JSON.parse(row.methods)

        for (const method of methodsArr) {
            methodTags.push(method.sendMethod)
        }
    }

    return (
        <tr key={row.serviceId}>
            <td><strong className={'text-primary'}>{row.serviceId}</strong></td>
            <td>{row.integration.toUpperCase()}</td>
            <td>{methodTags.map(m => (<TagChip key={m} tag={{ name: m }} />))}</td>
            <td>{row.configuredRssi}</td>
        </tr>
    )
}

export default ProfileTableRow
