import axios from 'axios'
import { TokenEcholo } from './token.echolo'
import { Application } from './interfaces/application.interface'
import { Profile, SplitProfiles } from './interfaces/profile.interface'

let tokenHeaders = (app, user) => {
    return {
        headers: {
            'x-api-key': user.xapi_key,
            'appId': app.appId,
            'token': app.token,
        }
    }
}
export const all = async (app: Application, user): Promise<SplitProfiles> => {
    try {
        const tokenData = new TokenEcholo(user)
        const updatedToken = await tokenData.getToken(app)
        const profile = await axios.get('/applications/profiles', tokenData.tokenHeaders({ ...app, token: updatedToken }))
        return SplitServiceTypes(profile.data)
    } catch (error) {
        console.log(error)
        return {
            auto_delivery: [],
            rule_delivery: []
        }
    }
}


const SplitServiceTypes = (profiles): SplitProfiles => {
    const splitProfiles: SplitProfiles = {
        auto_delivery: [],
        rule_delivery: []
    }
    profiles.forEach(profile => {
        if (profile.serviceType) {
            splitProfiles[profile.serviceType].push(profile)
        } else {
            splitProfiles.auto_delivery?.push(profile)
        }
    })
    return splitProfiles
}
