import React, { useState, useEffect } from 'react'
import LoadingSpinner from '../Shared/LoadingSpinner'

import * as Profile from '../../store/Api/Echolo/Profile'

import ProfileTable from './ProfileTable'

import { Application } from '../../store/Api/Echolo/interfaces/application.interface'
import { User } from '../../Interfaces/user.interface'
import { SplitProfiles } from '../../store/Api/Echolo/interfaces/profile.interface'

const ProfileList = (props) => {

    const ProfileState: SplitProfiles = {}

    const [loading, setLoading] = useState(true)
    const [profiles, setProfiles] = useState(ProfileState)
    const getProfiles = async () => {
        try {
            const profileData: SplitProfiles = await Profile.all(props.currentApp, props.currentUser)
            setProfiles(profileData)
            setLoading(false)
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        getProfiles()
    }, [])

    return (
        <div>
            {loading ?
                <LoadingSpinner />
                :
                <>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h4>Device Profiles</h4>
                            <p>Device Profiles, also known as Device Integration, allow you to use a specific type of device. All Device profiles are sent to Echolo first. You may add a Rule Profile below to deliver Device data to your systems.</p>
                        </div>
                        <div className={'col-12'}>
                            <ProfileTable profiles={profiles.auto_delivery} />
                        </div>
                    </div>

                    <div>
                        <hr />

                        <div className={'col-12'}>
                            <h4>Rule Delivery Profiles</h4>
                            <p>Rule Profiles allow you to specify where you want to send your results after a rule has run and met your conditions. Connect to services like IFTTT, MySQL, Redlist, and more.</p>
                        </div>
                        <div className={'col-12'}>
                            {profiles.rule_delivery && profiles.rule_delivery?.length > 0 ?
                                <ProfileTable profiles={profiles.rule_delivery} />
                            :
                                <p className={'text-primary'}><strong>No Delivery Profiles</strong> You dont have any Rule Delivery Profiles attached to this account yet.</p>
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ProfileList
