import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'

import * as Hub from '../../store/Api/Echolo/Hub' 

const HubG1Control = (props) => {
	let initState = false
	try {
		initState = props.hub.double.leds === 'ON' ? true : false
	} catch(e) {

	}
    
	const [ledStatus, setLedStatus] = useState(initState)
	const [rebootStatus, setRebootStatus] = useState(false)

	const toggleLEDs = (newStatus, e) => {
		setLedStatus(newStatus)
		let hubLEDStat = newStatus ? 'ON' : 'OFF'
		Hub.toggle_leds(props.currentApp, props.hub.hubId, hubLEDStat, props.currentUser).then(res => {
			console.log(res)
		})
	}

	const reboot = () => {
		setRebootStatus(true)

		Hub.reboot(props.currentApp, props.hub.hubId, props.currentUser).then(d => {
			console.log(d)
			setTimeout(() => setRebootStatus(false), 2000)
		})
	}


	return (
		<div className="HubControls">
			<p className="mb-0">Hub Controls:</p>
            
			{ledStatus ? 
				<button onClick={(e) => toggleLEDs(false, e)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon="lightbulb-slash" /> Turn LED Off</button> :
				<button onClick={(e) => toggleLEDs(true, e)} className="btn btn-success btn-sm"><FontAwesomeIcon icon="lightbulb-on" /> Turn LED On</button>}
            
			{rebootStatus ? <button disabled className="btn btn-danger btn-sm"><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Rebooting...</button>
				: <button onClick={(e) => reboot(e)} className="btn btn-danger btn-sm"><FontAwesomeIcon icon="power-off" /> Reboot</button>}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		currentUser: state.user.currentUser,
		currentApp: state.app.currentApp
	}
}
export default connect(mapStateToProps)(HubG1Control)