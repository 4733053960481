import axios from 'axios'
import { TokenEcholo } from './token.echolo'
import { Device } from './interfaces/device.interface'



export const DevicesAll = async (app, user): Promise<Device[]> => {
	const tokenData = new TokenEcholo(user)
	const updatedToken = await tokenData.getToken(app)
	return new Promise((resolve, reject) => {
		axios.get('/devices/', tokenData.tokenHeaders({ ...app, token: updatedToken })).then(res => {
			resolve(res.data)
		}).catch(e => {
			console.error(e.message)
			reject(e)
		})
	})
}

export const get = async (app, id, user): Promise<Device> => {
	const tokenData = new TokenEcholo(user)
	const updatedToken = await tokenData.getToken(app)
	return new Promise((resolve, reject) => {
		axios.get('/devices/' + id, tokenData.tokenHeaders({ ...app, token: updatedToken })).then(res => {
			resolve(res.data)
		}).catch(e => {
			console.error(e.message)
			reject(e)
		})
	})
}
