import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as TelemetryHistoryHelper from '../../Helpers/TelemetryHistoryHelper'


const TelemetrySearchRemoveBtn = (props) => {
	const removeHandler = (e) => {
		TelemetryHistoryHelper.removeAll()
		props.removeHistory()
	}

	return (
		<button onClick={e => removeHandler(e)} className="btn btn-sm btn-danger"><FontAwesomeIcon icon="trash-alt" /> Clear History</button>
	)
}
export default TelemetrySearchRemoveBtn
