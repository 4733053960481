import React from 'react'

import LoadingSpinner from '../../Components/Shared/LoadingSpinner'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ApplicationList = (props) => {
    let apps = null
    if (props.applications.length > 0) {
        apps = props.applications.map((item, key) => {
            return (
                <div className={"dropdown-item ApplicationList " + props.addedClass} key={item.appId}>
                    <p title={item.appId} className="appName" onClick={e => props.appSelect(e, item)}><FontAwesomeIcon icon="browser" /> {item.name}</p>
                </div>
            )
        })
    } else {
        apps = (
                <div className="dropdown-item ApplicationList">
                    <p title="noApp" className="appName">No Applications Yet!</p>
                </div>
            )

    }

	return (
		<div className="ApplicationList">
			{props.loading ? 
				<LoadingSpinner /> :
            
				apps
			}
		</div>
	)
}

export default ApplicationList