import React, { Component } from 'react'
import { connect } from 'react-redux'

import SectionHeader from '../../Components/Shared/SectionHeader'
import ApplicationApiUsage from '../../Components/Applications/ApplicationApiUsage'
import ProfileList from '../../Components/Profiles/ProfileList'

import { Application } from '../../Interfaces/application.interface'
import { User } from '../../Interfaces/user.interface'
class ApplicationInfo extends React.Component<{currentApp: Application, currentUser: User}, {}> {
	state = {
		showSecret: false,
		showKey: false
	}

	render() {
		return (
			<div>
				<SectionHeader icon="code" title="API & Usage"></SectionHeader>
				<div className="row">
					<div className="col-8">
						<section>
							<h3>Application Information</h3>
							<table className="table table-hover">
								<thead>
									<tr>
										<th className={'col-4'} scope="col">Name</th>
										<th className={'col-8'} scope="col">Data</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Application ID (appId)</td>
										<td><pre>{this.props.currentApp.appId}</pre></td>
									</tr>
									<tr>
										<td>Application Secret (secret)</td>
										<td><pre>{this.state.showSecret ? <span>{this.props.currentApp.secret} <span className="fake-link" onClick={() => this.setState({ showSecret: !this.state.showSecret })}>(hide)</span></span> : <span className="fake-link" onClick={() => this.setState({ showSecret: !this.state.showSecret })}>show</span>}</pre></td>
									</tr>
									<tr>
										<td>API Key (x-api-key)</td>
										<td><pre>{this.state.showKey ? <span>{this.props.currentUser.xapi_key} <span className="fake-link" onClick={() => this.setState({ showKey: !this.state.showKey })}>(hide)</span></span> : <span className="fake-link" onClick={() => this.setState({ showKey: !this.state.showKey })}>show</span>}</pre></td>
									</tr>
								</tbody>
							</table>
						</section>

						<section>
							<ProfileList currentUser={this.props.currentUser} currentApp={this.props.currentApp} />
						</section>
					</div>


					<div className="col-4">
						<section>
							<h3>API Usage</h3>
							<ApplicationApiUsage />
						</section>
					</div>
				</div>

				<div className={'row'}>

				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		currentUser: state.user.currentUser,
		currentApp: state.app.currentApp,
	}
}

export default connect(mapStateToProps)(ApplicationInfo)
