import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ServiceSummary } from '../../store/Api/StatusPage/Status'

const ServiceStatusMicroBadge = ({ statusData }) => {
	const [status, setStatus] = useState(statusData || '')

	useEffect(() => {
		let isMounted = true

		const getServiceStatus = () => {
			ServiceSummary().then(res => {
				// res.status.indicator = [major, minor, none]
				// description = String
				if (isMounted) setStatus(res.status)
			})
		}

		if (!status && !statusData) {
			getServiceStatus()
			setInterval(()=> getServiceStatus(), 60000*10)
		}

		return () => { isMounted = false }
	}, [status, statusData])

	return (
		<div className="ServiceStatusMicroBadge">
			<p>
				<span className={status.indicator}>
					{status.indicator === 'none' ? <FontAwesomeIcon icon="check" /> : null}
					{status.indicator === 'minor' ? <FontAwesomeIcon icon="exclamation" /> : null}
					{status.indicator === 'major' ? <FontAwesomeIcon icon="times" /> : null} </span>
				{status.description}
			</p>
		</div>
	)
}

export default ServiceStatusMicroBadge
