import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ServiceSummary } from '../../store/Api/StatusPage/Status'

const ServiceStatusBadge = ({ statusData, maintenanceData }) => {
    
	const [status, setStatus] = useState(statusData || '')
	const [maintenance, setMaintenance] = useState(maintenanceData || false)

	useEffect(() => {
		let isMounted = true

		const getServiceStatus = () => {
			ServiceSummary().then(res => {
				// res.status.indicator = [major, minor, none]
				// description = String
				if (isMounted) setStatus(res.status)
				if (isMounted) setMaintenance(res.scheduled_maintenances)
			})
		}

		if (!status && !statusData) {
			getServiceStatus()
			setInterval(()=> getServiceStatus(), 60000*10)
		}

		return () => { isMounted = false }
	}, [status, statusData])

	return (
		<div className="ServiceStatusBadge">
			<a target="_blank" rel="noopener noreferrer" href="https://echoloiot.statuspage.io/">
                IoT Services <span className={status.indicator}>
					{status.indicator === 'none' ? <FontAwesomeIcon icon="check" /> : null}
					{status.indicator === 'minor' ? <FontAwesomeIcon icon="exclamation" /> : null}
					{status.indicator === 'major' ? <FontAwesomeIcon icon="times" /> : null}
					{status.description}
				</span>
			</a>
			{maintenance.length > 0 ? 
				<a className="ServiceStatusBadgeMaintenance" target="_blank" rel="noopener noreferrer" href="https://echoloiot.statuspage.io/">
					<FontAwesomeIcon icon="wrench" /> Upcoming Maintenance
				</a>
				: null }
		</div>
	)
}

export default ServiceStatusBadge
