import React from 'react'
import { Link } from 'react-router-dom'

const HubShowSubMenu = ({location, hubId}) => {
	return (
		<div className="row SectionSubHeader">
			<div className="col-12">
				<ul>
					<li><Link className={location.includes('/hubs') && !location.includes('telemetry') && !location.includes('double') ? 'active' : ''} to={'/hubs/' + hubId}>Hub [{hubId}]</Link></li>
					<li><Link className={location.includes('/double') ? 'active' : ''} to={'/hubs/' + hubId + '/double'}>Double</Link></li>
					<li><Link className={location.includes('/telemetry') ? 'active' : ''} to={'/hubs/' + hubId + '/telemetry'}>Telemetry</Link></li>
				</ul>
			</div>
		</div>
	)
}

export default HubShowSubMenu