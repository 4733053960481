import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'

import UserEditForm from '../../Components/User/UserEditForm'
import SectionHeader from '../../Components/Shared/SectionHeader'

class User extends Component {
    state = {
        userSavedMsg: '',
        userSavedMsgClass: ''
    }

    userUpdateHandler = (status) => {
        if (status) {
            this.setState({
                userSavedMsg: 'You have successfully updated your information!',
                userSavedMsgClass: 'text-success'
            })
        } else {
            this.setState({
                userSavedMsg: 'There was an issue updating your information, please try again.',
                userSavedMsgClass: 'text-danger'
            })
        }
    }

    componentWillUnmount = () => {
        this.setState({
            userSavedMsg: ''
        })
    }

    render() {
        return (
            <div>
                <SectionHeader icon="user-astronaut" title={this.props.currentUser.email}></SectionHeader>
                <div className="row">
                    <div className="col-6">
                        <section>
                            <h4>Update Your Information</h4>
                            <UserEditForm redirectHandler={this.userUpdateHandler} user={this.props.currentUser} />
                            <p className={this.state.userSavedMsgClass}>{this.state.userSavedMsg}</p>
                        </section>
                    </div>

                    <div className="col-6">
                        <section>
                            <h4>Support</h4>
                            <ul>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://echoloiot.atlassian.net/servicedesk/customer/portals">Support Portal</a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://echoloiot.atlassian.net/servicedesk/customer/portals">Create a NEW Support Ticket</a></li>
                                <hr />
                                <li><a target="_blank" rel="noopener noreferrer" href={'https://support.echolo.io/help/announcements'}>Platform Announcements</a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://api.echolo.io/swagger">REST API Swagger Documents</a></li>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentUser: (user) => dispatch({ type: Actions.SET_CURRENT_USER, payload: user })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(User)
