import React from 'react'

import GatewayListItem from './GatewayListItem'

const GatewaysList = (props) => {

	const gateways = props.gateways.map((gateway, key) => {
		return (
			<GatewayListItem key={gateway.gatewayId} gateway={gateway} />
		)
	})

	return (
		<div className="Gateways">
			<table className="table table-hover">
				<thead>
					<tr>
						<th scope="col">Gateway ID</th>
						<th scope="col">Key</th>
						<th scope="col">SKU</th>
					</tr>
				</thead>
				<tbody>
					{gateways}
				</tbody>
			</table>
		</div>
	)
}

export default GatewaysList
