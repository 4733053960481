import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ServiceSummary } from '../../store/Api/StatusPage/Status'

import ServiceStatusMicroBadge from './ServiceStatusMicroBadge'
import ServiceStatusIncidentsItem from './ServiceStatusIncidentsItem'

class ServiceStatusIncidents extends Component {
    state = {
        status: null,
        summary: null,
        isIncident: false,
        scheduled_maintenances: null
    }

    timer = null
    getStatusUpdates = () => {
        ServiceSummary().then(res => {
            // res.status.indicator = [major, minor, none]
            // incidents = Array
            let isIncident = res.status.indicator !== "none" ? true : false
            this.setState({
                status: res.incidents,
                summary: res.status,
                isIncident: isIncident,
                scheduled_maintenances: res.scheduled_maintenances
            })

            // Only start if there is an incident
            if (isIncident) {
                this.timer = setInterval(()=> this.getStatusUpdates(), 60000*2)
            }

            this.props.serviceStatus(isIncident)
            
        })
    }

    componentDidMount = () => {
        this.getStatusUpdates()
    }

    componentWillUnmount = () => {
        clearInterval(this.timer)
        this.timer = null
    }


    render() {
        let incidents = []
        if (this.state.status) {
            incidents = this.state.status.map((incident, key) => {
                return (
                    <ServiceStatusIncidentsItem key={key} incident={incident} />
                )
            })
        }
        return (
            <>
            {this.state.summary ?
            <div className={this.state.summary.indicator === 'none' ? "col-3" : "col-4"}>
                <section className="DashboardStatBox">
                    <h4><FontAwesomeIcon icon="shield-check" /> Service Status</h4>
                    <div className="text-center">
                        <ServiceStatusMicroBadge statusData={this.state.summary} />
                    </div>
                    <div className="row ServiceStatusIncidents">
                        <div className="col-12">
                            {incidents}
                        </div>
                    </div>  
                </section>
            </div> : null
            }</>
        )
    }
}

export default ServiceStatusIncidents
