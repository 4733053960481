import React from 'react'

const ErrorPage = () => {
	return (
		<div>
			<p>404 ERROR</p>
		</div>
	)
}

export default ErrorPage