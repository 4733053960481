import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { formatDateShort } from '../../Helpers/DateHelper'

const HubTemplateList = ({ templates, canApply = false, apply }) => {
	const templateLength = templates.length

	let templateData = (
		<div className="row justify-content-center">
			<div className="col-8">
				<div className="alert alert-warning">
					<div className="row">
						<div className="col-12">
							<h3><FontAwesomeIcon icon="brackets" /> No Templates</h3>
							<hr />
							<p>There are no templates found for your account, you may create a new one here.</p>
							<p><small>Hub Templates are JSON Objects that are used to provide you with an easy way to create default Hub Doubles. 
                                These templates are built and then applied to a Hub Double, allowing you to use default settings to deployed Hubs.</small></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)

	if (templateLength > 0) {
		templateData = templates.map((temp, key) => {
			return (
				<tr key={key}>
					{ canApply ? <td onClick={() => apply(temp) }><FontAwesomeIcon icon="arrow-to-left" /> Apply to Hub</td> : null }
					<td><Link to={'/hub/templates/' + temp.templateId}>{temp.name}</Link></td>
					{ canApply ? null : <td><Link to={'/hub/templates/' + temp.templateId}>{temp.templateId}</Link></td> }
					<td>{formatDateShort(temp.createdAt)}</td>
				</tr>
			)
		})
	}
	return (
		<>
			{templateLength > 0 ?
				<table className="table table-hover">
					<thead>
						<tr>
							{ canApply ? <th scope="col">Apply</th> : null }
							<th scope="col">Name</th>
							{ canApply ? null : <th scope="col">Template ID</th> }
							<th scope="col">Created At</th>
						</tr>
					</thead>
					<tbody>
						{templateData}
					</tbody>
				</table> 
				: templateData}
		</>
	)
}

export default HubTemplateList