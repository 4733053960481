import axios from 'axios'

import { User } from './interfaces/user.interface'
import { APIError } from './interfaces/error.interface'
let headers = (user) => {
	return {
		headers: {
			'X-User-Token': user.authentication_token,
			'X-User-Email': user.email
		}
	}
}

let xapiOnly = (user) => {
	return {
		headers: {
			'x-api-key': user.xapi_key,
		}
	}
}

export const create = (user): Promise<User | APIError> => {
	let data = {
		userId: user.userId
	}
	return new Promise((resolve, reject) => {
		axios.post('/users/', data, xapiOnly(user)).then(res => {
			if (res.data.error) reject(new APIError(res.data))
			resolve(res.data)
		}).catch(e => {
			reject(e.message)
		})
	})
}

export const login = (data) => {
	return new Promise((resolve, reject) => {
		axios.post('/sessions', data).then(res => {
			resolve(res.data)
		}).catch(e => {
			reject(e.message)
		})
	})
}

export const update = (currentUser, data) => {
	return new Promise((resolve, reject) => {
		axios.put('/users/', data, xapiOnly(currentUser)).then(res => {
			resolve(res.data)
		}).catch(e => {
			reject(e.message)
		})
	})
}

export const get = (user) => {
	return new Promise((resolve, reject) => {
		axios.get('/users/' + user.id, xapiOnly(user)).then(res => {
			resolve(res.data)
		}).catch(e => {
			reject(e.message)
		})
	})
}

