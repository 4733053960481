import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class Feverinspect extends Component {

    state = {
        pieChartData: {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: '10/1/2020 Pass/Fail Rate'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            colors: ['#73cc88', '#ce7564'],
            series: [{
                name: 'Pass/Fail',
                colorByPoint: true,
                data: [{
                    name: 'Pass',
                    y: 98.2
                }, {
                    name: 'Fail',
                    y: 1.8
                }]
            }]
        },

        chartData: {
            chart: {
                scrollablePlotArea: {
                    minWidth: 600,
                    scrollPositionX: 1
                }
            },
            title: {
                text: 'Latest Temperature Scans',
                align: 'left'
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    overflow: 'justify'
                }
            },
            yAxis: {
                title: {
                    text: 'Temperature Zones'
                },
                minorGridLineWidth: 0,
                gridLineWidth: 0,
                alternateGridColor: null,
                plotBands: [{ // Light air
                    from: 90,
                    to: 98.6,
                    color: 'rgba(121, 214, 124, 0.1)',
                    label: {
                        text: 'Safe',
                        style: {
                            color: '#606060'
                        }
                    }
                }, { // Light breeze
                    from: 98.7,
                    to: 99.3,
                    color: 'rgba(199, 196, 92, 0.1)',
                    label: {
                        text: 'Warning',
                        style: {
                            color: '#606060'
                        }
                    }
                }, { // Gentle breeze
                    from: 99.4,
                    to: 120,
                    color: 'rgba(199, 92, 92, 0.1)',
                    label: {
                        text: 'Danger',
                        style: {
                            color: '#606060'
                        }
                    }
                }]
            },
            tooltip: {
                valueSuffix: ' ℉'
            },
            plotOptions: {
                series: {
                    lineWidth: 4,
                    states: {
                        hover: {
                            lineWidth: 5
                        }
                    },
                    marker: {
                        enabled: false
                    },
                    pointInterval: 3600000, // one hour
                    pointStart: Date.now()
                }
            },
            colors: ['#2b2b2b'],
            series: [{
                name: 'Temperature',
                data: [
                    98.6, 98.4, 97.9, 99.1, 99.4, 96.9, 98.5, 98.3, 101.4, 98.5, 97.9
                ]
            }],
            navigation: {
                menuItemStyle: {
                    fontSize: '10px'
                }
            }
        },

        AmbientChartData: {
            chart: {
                type: 'spline',
                scrollablePlotArea: {
                    minWidth: 600,
                    scrollPositionX: 1
                }
            },
            title: {
                text: 'Ambient Temperature Today',
                align: 'left'
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    overflow: 'justify'
                }
            },
            yAxis: {
                title: {
                    text: 'Temperature'
                },
                minorGridLineWidth: 0,
                gridLineWidth: 0,
                alternateGridColor: null,
                plotBands: [{ // Light air
                    from: 74,
                    to: 73.5,
                    color: 'rgba(121, 214, 124, 0.1)',
                    label: {
                        text: 'Ideal Zone',
                        style: {
                            color: '#90c1ca'
                        }
                    }
                }]
            },
            tooltip: {
                valueSuffix: ' ℉'
            },
            plotOptions: {
                spline: {
                    lineWidth: 4,
                    states: {
                        hover: {
                            lineWidth: 5
                        }
                    },
                    marker: {
                        enabled: false
                    },
                    pointInterval: 3600000, // one hour
                    pointStart: Date.now()
                }
            },
            colors: ['#2b2b2b'],
            series: [{
                name: 'Temperature',
                data: [
                    74.1, 74.3, 74, 73.8, 73.5, 73.6, 73.8, 73.5, 73.4, 73.1, 73.3
                ]
            }],
            navigation: {
                menuItemStyle: {
                    fontSize: '10px'
                }
            }
        }
    }


    render() {
        return (
            <div className="FeverInspect">
                <div className="row">
                    <div className="col-4">
                        <section className="TempWarn TempHeaderBox">
                            <h4>Current Scan</h4>
                            <p className="BigTemp">99.1 &#8457;</p>
                        </section>
                    </div>

                    <div className="col-4">
                        <section className="TempPass TempHeaderBox">
                            <h4>Previous Scan</h4>
                            <p className="BigTemp">98.5 &#8457;</p>
                        </section>
                    </div>

                    <div className="col-4">
                        <section className="TempHeaderBox">
                            <h4>Environment</h4>
                            <p className="TempList"><strong>Ambient Temperature:</strong> 73.1 &#8457;</p>
                            <p className="TempList"><strong>Black body 1:</strong> 90 &#8457;</p>
                            <p className="TempList"><strong>Black body 2:</strong> 99 &#8457;</p>
                        </section>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <section>
                            <h4>Fever Inspect</h4>
                            <HighchartsReact highcharts={Highcharts} options={this.state.chartData} />
                        </section>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <section>
                            <h4>Today's Pass/Fail</h4>
                            <HighchartsReact highcharts={Highcharts} options={this.state.pieChartData} />
                        </section>
                    </div>

                    <div className="col-6">
                        <section>
                            <h4>Ambient Temperature</h4>
                            <HighchartsReact highcharts={Highcharts} options={this.state.AmbientChartData} />
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

export default Feverinspect
