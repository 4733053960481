import React from 'react'
import { Link } from 'react-router-dom'

import * as DateHelper from '../../Helpers/DateHelper'

const DeviceListItem = (props) => {
	let name = props.device.name
	if (props.device.nodeType === 'OEM') {
		name = props.device.profilePacket.serial_number
	}
	return (
		<tr>
			<td><Link to={'/device/' + props.device.nodeId}>{name} / {props.device.nodeId}</Link></td>
			<td>{props.device.rssi}</td>
			<td>{props.device.measuredDistance}</td>
			<td>{props.device.nodeType}</td>
			<td>{props.device.rangeGuess}</td>
			<td>{DateHelper.formatDate(props.device.lastSeenDate)}</td>
		</tr>
	)
}

export default DeviceListItem
