import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LoginForm = (props) => {
	const logValues = {
		email: '',
		password: ''
	}
	const { register, handleSubmit, errors } = useForm({reValidateMode: "onBlur"})

	const [userLogin, setUserLogin] = useState(logValues)

	return (
		<form onSubmit={handleSubmit(props.login)}>
			<div className="LoginForm">
				<div className="form-group">
					{errors.email && <p className="text-danger">Email is required.</p>}
					<input value={userLogin.email} 
						name="email"
						onChange={e => setUserLogin({ ...userLogin, email: e.target.value })} 
						type="email" 
						placeholder="Email Address" 
						className="form-control form-control-lg" id="email"
						ref={register({ required: true })} />
				</div>
				<div className="form-group">
					{errors.password && <p className="text-danger">Password is required.</p>}
					<input value={userLogin.password} 
						name="password"
						onChange={e => setUserLogin({ ...userLogin, password: e.target.value })} 
						type="password" 
						placeholder="Password" 
						className="form-control form-control-lg" id="password"
						ref={register({ required: true })} />
				</div>
				<div className="row">
					<div className="col-8">
						<p className="text-size-medium">Don't have an account? <Link to="/register">Register here</Link>.</p>
					</div>
					<div className="col-4">
						<button type="submit" className="btn btn-outline-primary btn-lg float-right">Sign In <FontAwesomeIcon icon="chevron-right" /></button>
					</div>
				</div>
                
			</div>
		</form>
	)
}

export default LoginForm
