import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'

import * as TelemetryHistoryHelper from '../../Helpers/TelemetryHistoryHelper'

const TelemetrySearchHistory = (props) => {
	let history = null
	let TelemetrySearchHistory = props.telemSearchHistory || []

	const removeHistoryItemHandler = (e, key) => {
		TelemetrySearchHistory = TelemetryHistoryHelper.remove(props.telemSearchHistory, key)
		console.log('Remove Item: ', TelemetrySearchHistory)
		$('#historyItem_'+key).hide('slow')
	}

	if (TelemetrySearchHistory.length > 0) {
		history = TelemetrySearchHistory.map((item, key) => {
			let objKey = Object.keys(item)
			return (
				<span id={'historyItem_'+key} key={key} className="badge badge-light TelemetrySearchHistory">
					<span onClick={e => removeHistoryItemHandler(e, key)}><FontAwesomeIcon className="TelemetrySearchHistoryRemoveItem" icon="trash-alt" /></span>
					<Link to={'/telemetry?'+ objKey[0] + '='+ item[objKey[0]]}>{objKey[0]}: {item[objKey[0]]}</Link>
				</span>
			)
		})
	}

	return history
}

export default TelemetrySearchHistory
