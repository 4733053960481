import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DeviceListItem from './DeviceListItem'

import * as DeviceHelper from '../../Helpers/DeviceHelper'


/*
    [listType] Boolean
    [devices] Array(Object)
    [showInPast] => Hours: 4=4 hours | 24*5=5 days
*/
class DeviceList extends Component {

    // Split the devices into two arrays
    // Online and Offline with DeviceHelper.status(date)
    state = {
        online: [],
        offline: [],
        allDevices: []
    }

    _isMounted = true

    buildDeviceList = () => {
        DeviceHelper.filterOldDevices(this.props.devices, this.props.showInPast || 24*5).then(cleaned => {
            let devicesOnline = []
            let devicesOffline = []
            cleaned.forEach(device => {
                if (DeviceHelper.status(device.lastSeenDate)) {
                    // Online
                    devicesOnline.push(device)
                } else {
                    devicesOffline.push(device)
                }
            })

            if (this.props.counts) this.props.counts(devicesOnline, devicesOffline)

            if (this._isMounted) {
                this.setState({
                    allDevices: cleaned,
                    online: devicesOnline,
                    offline: devicesOffline
                })
            }
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.devices !== prevProps.devices) {
            this.buildDeviceList()
        }
    }

    componentDidMount = () => {
        this._isMounted = true
        this.buildDeviceList()
    }

    componentWillUnmount = () => {
        this._isMounted = false
    }

    render() {
        let online = null
        let offline = null
        let onlineTotalDevices = this.state.online.length
        let offlineTotalDevices = this.state.offline.length
        let display = null
        let noDevicesMessage = null

        online = this.state.online.map((device, key) => {
            return (
                <DeviceListItem device={device} key={key} />
            )
        })

        offline = this.state.offline.map((device, key) => {
            return (
                <DeviceListItem device={device} key={key} />
            )
        })

        display = (
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Name / ID</th>
                        <th scope="col">RSSI</th>
                        <th scope="col">Measured Distance (ft)</th>
                        <th scope="col">Profile</th>
                        <th scope="col">Range Guess (ft)</th>
                        <th scope="col">Time last seen</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.listType === "online" ? online : offline}
                </tbody>
            </table>
        )

        noDevicesMessage = (
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                    <div className="alert alert-warning">
                        <div className="row">
                            <div className="col-12">
                                <h3><FontAwesomeIcon icon="microchip" /> No Devices</h3>
                                <hr />
                                <p>There are no {this.props.listType} devices seen right now, this page will automatically update with fresh data shortly.</p>
                                {this.props.listType === 'offline' ? <p><strong>Offline devices older than 5 days will not show on this list.</strong></p> : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2"></div>
            </div>
        )

        if (this.props.listType === "online" && onlineTotalDevices === 0) {
            display = noDevicesMessage
        }
        if (this.props.listType === "offline" && offlineTotalDevices === 0) {
            display = noDevicesMessage
        }

        return (
            <>  
                {display}
            </>
        )
    }
}

export default DeviceList
