import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ServiceStatusIconHelper = ({ status }) => {
	return (
		<>
			{status === 'operational' ? <FontAwesomeIcon className={status} icon="check" /> : null }
			{status === 'under_maintenance' ? <FontAwesomeIcon className={status} icon="wrench" /> : null }
			{status === 'degraded_performance' ? <FontAwesomeIcon className={status} icon="exclamation" /> : null }
			{status === 'partial_outage' ? <FontAwesomeIcon className={status} icon="exclamation" /> : null }
			{status === 'major_outage' ? <FontAwesomeIcon className={status} icon="times" /> : null}
		</>
	)
}

export default ServiceStatusIconHelper
