import axios from 'axios'

let headers = (user) => {
	return {
		headers: {
			'X-User-Token': user.authentication_token,
			'X-User-Email': user.email
		}
	}
}

let tokenHeaders = (app, user) => {
	return {
		headers: {
			'x-api-key': user.xapi_key,
			'appId': app.appId,
			'token': app.token,
		}
	}
}


export const update = (app, id, data, user) => {
	return new Promise((resolve, reject) => {
		axios.put('/hubs/' + id + '/double', { double: data }, tokenHeaders(app, user)).then(res => {
			resolve(res.data)
		}).catch(e => {
			reject(e)
		})
	})
}

