import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import * as HubHelper from '../../Helpers/HubHelper'
import * as DateHelper from '../../Helpers/DateHelper'


const HubList = ({ hubData, loading, listType, counts }) => {
	const [hubElm, setHubElm] = useState(null)

	useEffect(() => {
		let hubs = null,
			hubElement = null,
			onlineHubs = [],
			offlineHubs = []

		if (hubData.length > 0) {
			hubData.forEach(hub => {
				if (HubHelper.status(hub.lastSeenDate)) {
					onlineHubs.push(hub)
				} else {
					offlineHubs.push(hub)
				}
			})

			counts(onlineHubs, offlineHubs)

			let loopHubs = onlineHubs
			if (listType === 'offline') {
				loopHubs = offlineHubs
			}

			hubs = loopHubs.map((item, key) => {
				return (
					<tr key={item.hubId + key}>
						<th scope="row">{HubHelper.statusIcon(item.lastSeenDate)}</th>
						<td><Link to={'/hubs/' + item.hubId}>{item.hubId}</Link></td>
						<td>{HubHelper.statusIcon(item.heartBeat || false, 'comms')}</td>
						<td>{item.sku} (<strong>{HubHelper.hubType(item.sku)}</strong>)</td>
						<td>{DateHelper.formatDate(item.lastSeenDate)}</td>
					</tr>
				)
			})
		}

		hubElement = (
			<div className="spinner-border text-primary" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		)

		if (!loading) {
			hubElement = (

				<table className="table table-hover">
					<thead>
						<tr>
							<th scope="col">Status</th>
							<th scope="col">HubId</th>
							<th scope="col">Activity</th>
							<th scope="col">SKU</th>
							<th scope="col">Last Seen</th>
						</tr>
					</thead>
					<tbody>
						{hubs}
					</tbody>
				</table>
			)
		}

		setHubElm(hubElement)
	}, [counts, hubData, listType, loading])

	return (
		<div>
			{hubElm}
		</div>
	)
}

export default HubList
