import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Menu Componets
import Logout from '../../Components/Logout/Logout'

import ServiceStatusMicroBadge from '../../Helpers/ServiceStatus/ServiceStatusMicroBadge'
import Applications from '../../pages/Applications/Applications'

const TopMenu = (props) => {
	return (
		<ul className="NavList">
			<li><Logout /></li>
			<li><Link title="Edit Your Profile" className={props.locationPath === '/user' ? 'rounded active' : 'rounded'} to="/user"><FontAwesomeIcon icon="user-astronaut" /></Link></li>
			<li><Link to="/servicestatus" className={props.locationPath === '/servicestatus' ? 'rounded active' : 'rounded'}><FontAwesomeIcon icon="shield-check" /></Link></li>
			<li><a title="Support" className="rounded" href="https://support.echolo.io/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon="question-circle" /></a></li>

			<li><ServiceStatusMicroBadge /></li>

			<li className="float-right">
				<div className="dropdown ApplicationSelectButton">
					<button className="btn btn-outline-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						{!_.isEmpty(props.currentApp) ? <>{props.currentApp.name}</> : 'Select Application'}
					</button>
					<div className="dropdown-menu ApplicationSelectButtonList" aria-labelledby="dropdownMenuButton">
						<Applications addedClass="MenuApplicationList" />
					</div>
				</div>
			</li>
			{!_.isEmpty(props.currentApp) && props.currentApp.telemetry_flag ?
				<li className="float-right"><Link className={props.locationPath === '/telemetry' ? 'rounded active' : 'rounded'} to="/telemetry"><FontAwesomeIcon icon="terminal" /> Telemetry</Link></li> : null
			}

			{!_.isEmpty(props.currentApp) && props.currentApp.rtls_flag ?
				<li className="float-right"><Link className={props.locationPath === '/explorer' ? 'rounded active' : 'rounded'} to="/explorer"><FontAwesomeIcon icon="satellite" /> Explorer</Link></li> : null
			}
		</ul>
	)
}

const mapStateToProps = state => {
	return {
		currentUser: state.user.currentUser,
		currentApp: state.app.currentApp
	}
}
export default connect(mapStateToProps)(TopMenu)