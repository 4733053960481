import React, {Component} from 'react'
import {connect} from 'react-redux'
import {ProgressBar, Spinner} from 'react-bootstrap'
import * as moment from 'moment'

import { ApplicationApiUsageTable } from './ApplicationApiUsageTable'

import { apiUsage } from '../../store/Api/Echolo/Application'
import _ from "lodash";

import { Application } from '../../Interfaces/application.interface'
import { APIError } from '../../store/Api/Echolo/interfaces/error.interface'
import { User } from '../../Interfaces/user.interface'
import {instanceOf} from "prop-types";

type appInfoState = {
    usage?: Application,
    loading: boolean,
    apiMax: number,
    currentUse: string,
}
class ApplicationApiUsage extends React.Component<{currentApp: Application, currentUser: User}, appInfoState> {
    state: appInfoState = {
        loading: true,
        apiMax: 0,
        currentUse: '0'
    }
    getUsageData = async () => {
        try {
            const apiUsageData = await apiUsage(this.props.currentApp, this.props.currentUser)
            if (apiUsageData instanceof(APIError)) throw apiUsageData.error.error

            let currentApiUsage: number = 0
            let maxApiUsage: number = 0
            if (apiUsageData.apiUsage && apiUsageData.apiUsage?.usage) {
                for( const d of apiUsageData.apiUsage?.usage.usageData) {
                    currentApiUsage += d[0]
                }

                if (apiUsageData.apiUsage?.usage.usageData[0]) maxApiUsage = apiUsageData.apiUsage?.usage.usageData[0][1]
            }

            const usagePst = ((currentApiUsage / maxApiUsage) * 100).toFixed(0)
            this.setState({
                usage: apiUsageData,
                loading: false,
                currentUse: isNaN(parseFloat(usagePst)) ? '0' : usagePst,
            })
        } catch (error) {
            console.log(error)
            this.setState({
                loading: false
            })
        }
    }

    async componentDidMount() {
        if (!_.isEmpty(this.props.currentApp)) {
            await this.getUsageData()
        }
    }
    render() {
        return (
            <div>
                {
                    this.state.loading ?
                        <div className={'row'}>
                            <div className={'col-12 text-center'}>
                                <Spinner animation="grow" />
                            </div>
                        </div>
                        :
                        <>
                            {
                                !_.isEmpty(this.state.usage) ?
                                    <div className={'row'}>
                                        <div className={'col-6'}>
                                            <p>{moment.default(this.state.usage?.apiUsage?.startDate).format('MM/DD/YYYY')}</p>
                                        </div>
                                        <div className={'col-6 text-right'}>
                                            <p>{moment.default().format('MM/DD/YYYY')}</p>
                                        </div>
                                        <div className={'col-12'}>
                                            <ProgressBar now={parseInt(this.state.currentUse)} label={this.state.currentUse+'%'} />
                                        </div>

                                        <div className={'col-12'}>
                                            <ApplicationApiUsageTable apiUsageData={this.state.usage?.apiUsage} />
                                        </div>
                                    </div>
                                    : <p className={'text-primary'}>This Application is not setup to use the Echolo IoT API. Please contact support for more information.</p>}
                        </>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
    }
}

export default connect(
    mapStateToProps,
)(ApplicationApiUsage);
