import React from 'react'
import { Link } from 'react-router-dom'

const DevicesSubMenu = ({ location, deviceCounts }) => {
	return (
		<div className="row SectionSubHeader">
			<div className="col-12">
				<ul>
					<li><Link className={location === '' ? 'active' : ''} to={'/devices'}>Online [{deviceCounts.online}]</Link></li>
					<li><Link className={location.includes('?offline=true') ? 'active' : ''} to={'/devices?offline=true'}>Offline [{deviceCounts.offline}]</Link></li>
				</ul>
			</div>
		</div>
	)
}

export default DevicesSubMenu