import axios from 'axios'
import { status } from '../../../Helpers/HubHelper'
import { status as deviceStatus } from '../../../Helpers/DeviceHelper'
import { TokenEcholo } from './token.echolo'
import { Hub } from './interfaces/hub.interface'
import { Device } from './interfaces/device.interface'
import {Application, ApplicationNumbers} from './interfaces/application.interface'
import { APIError } from './interfaces/error.interface'




export const all = (user) => {
	return new Promise((resolve, reject) => {
		axios.post('/users/apps', { userId: user.userId }, { headers: {
				'x-api-key': user.xapi_key,
			} }).then(res => {
			resolve(res.data)
		}).catch(e => {
			console.log('ALL apps:', e)
			reject(e.message)
		})
	})
}

export const numbers = async (app, user): Promise<ApplicationNumbers> => {
	// applications/ID/numbers
	const tokenData = new TokenEcholo(user)
	const updatedToken = await tokenData.getToken(app)
	return new Promise(async (resolve, reject) => {
		try {
			const devices: Device[] = (await axios.get('/devices', tokenData.tokenHeaders({ ...app, token: updatedToken }))).data
			const hubs: Hub[] = (await axios.get('/hubs', tokenData.tokenHeaders({ ...app, token: updatedToken }))).data

			let onlineHubs: Hub[] = []
			let offlineHubs: Hub[] = []

			let onlineDevices: Device[] = []
			let offlineDevices: Device[] = []

			if (Array.isArray(hubs)) {
				for( const hub of hubs ) {
					if (status(hub.lastSeenDate)) {
						onlineHubs.push(hub)
					} else {
						offlineHubs.push(hub)
					}
				}
			}

			if (Array.isArray(devices)) {
				for(const device of devices) {
					if (deviceStatus(device)) {
						onlineDevices.push(device)
					} else {
						offlineDevices.push(device)
					}
				}
			}

			resolve({ hubNumbers: { onlineHubs, offlineHubs }, deviceNumbers: { onlineDevices, offlineDevices } })
		} catch (error) {
			console.log(error)
		}
	})
}

export const apiUsage = async (app, user): Promise<Application | APIError> => {
	const tokenData = new TokenEcholo(user)
	const updatedToken = await tokenData.getToken(app)
	return new Promise(async (resolve, reject) => {
		try {
			const usage = (await axios.get('/applications', tokenData.tokenHeaders({ ...app, token: updatedToken }))).data
			if (usage.error) resolve(new APIError(usage))
			else resolve(usage)
		} catch (error) {
			reject(error)
		}
	})
}

export const activate = async (user, data): Promise<any> => {
	const tokenData = new TokenEcholo(user)
	return new Promise(async (resolve, reject) => {
		try {
			const newApp = (await axios.post('/applications/activate', data, tokenData.xapiOnly())).data
			if (newApp.error) reject(new APIError(newApp))
			await axios.post('/users/application/add', { appId: newApp.appId, userId: user.userId }, tokenData.xapiOnly())
			resolve(newApp)
		} catch (error) {
			reject(error)
		}
	})
}
