import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import * as Actions from '../../store/actions'

import ApplicationList from '../../Components/Applications/ApplicationList'

import * as Application from '../../store/Api/Echolo/Application'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Applications extends Component {
    state = {
        applicationLoading: true,
        redirectToDash: false,
        apps: []
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.addedApp !== this.props.addedApp) {
            const updatedCurrentUserApps = [ ...this.props.currentUserApplications, nextProps.addedApp ]
            this.props.setCurrentUserApplications(updatedCurrentUserApps)
            this.setState({
                applicationLoading: false,
                apps: updatedCurrentUserApps
            })
        }
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.currentApp)) {
            this.setState({
                applicationLoading: false
            })
        }

        if (_.isEmpty(this.props.currentApp) && !_.isEmpty(this.props.currentUser)) {
            Application.all(this.props.currentUser).then(data => {
                if (this.props.hasApps) this.props.hasApps(data)
                this.props.setCurrentUserApplications(data)
                this.setState({
                    applicationLoading: false,
                    apps: data
                })
            })
        }
        
    }

    applicationSelectHandler = (e, data) => {
        // Handel App select
        this.props.setCurrentApp(data)
        this.props.setApplicationNumbers({})
        this.setState({
            applicationLoading: false,
            redirectToDash: true
        })
    }

    currentApplicationRemoveHandler = (e) => {
        this.props.setCurrentApp({})
        this.props.setHubList([])
        this.props.setApplicationNumbers({})

        // Load all the apps from localStorage
        Application.all(this.props.currentUser).then(data => {
            this.props.setCurrentUserApplications(data)
            this.setState({
                applicationLoading: false,
                redirectToDash: true,
                apps: data
            })
        })
    }

    render() {
        let currentApp = null
        if (!_.isEmpty(this.props.currentApp)) {
            currentApp = this.props.currentApp
        }

        return (
            <div>
                {this.state.redirectToDash ? <Redirect to="/dashboard" /> : null}
                {!_.isEmpty(currentApp) && !this.state.applicationLoading ?
                <>
                    <div className="SelectedApplication">
                        <p className={'pl-2 pb-0 mb-0 text-center'}><small className={'text-muted'}>Currently selected Application:</small></p>
                        <p className="dropdown-item pt-2 pb-2 mb-1"><FontAwesomeIcon icon="browser" /> {currentApp.name}</p>
                        <p className="text-center pl-2 pr-2 mb-1"><button className="btn btn-md btn-block btn-outline-info" onClick={e => this.currentApplicationRemoveHandler(e)} ><FontAwesomeIcon icon="sync" /> Swap App</button></p>
                    </div>
                </> : <ApplicationList addedClass={this.props.addedClass} loading={this.state.applicationLoading} appSelect={this.applicationSelectHandler} applications={this.state.apps} />}
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentUserApplications: state.app.applications,
        currentApp: state.app.currentApp
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentApp: (apps) => dispatch({ type: Actions.SET_CURRENT_APPLICATION, payload: apps }),
        setCurrentUserApplications: (apps) => dispatch({ type: Actions.SET_CURRENT_USER_APPLICATIONS, payload: apps }),
        setHubList: (hubs) => dispatch({ type: Actions.SET_HUB_LIST, payload: hubs }),
        setApplicationNumbers: (data) => dispatch({ type: Actions.SET_APPLICATION_NUMBERS, payload: data })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(Applications)
