import params from 'query-params'

export const getParams = (urlParams) => {
	let newQueryArray = []
	let queryParams = params.decode(urlParams.replace('?', ''))

	Object.keys(queryParams).forEach((objKey, index) => {
		let qObj = {}
		qObj[objKey] = queryParams[objKey]
		newQueryArray.push(qObj)
	})
    

	return newQueryArray
}

export const encode = (obj) => {
	return params.encode(obj)
}
