import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Map as Gmap, Marker, GoogleApiWrapper} from 'google-maps-react'

import * as Hub from '../../store/Api/Echolo/Hub'

class Map extends Component {

    state = {
        hubs: []
    }

    componentDidMount = () => {
        let cleanHubs = []
        // Grab Hub's and Devices?
        Hub.all(this.props.currentApp, this.props.currentUser).then(data => {
            console.log("Hubs Data: ", data)
            cleanHubs = data.forEach((hub, key) => {
                if (hub.double) {
                    cleanHubs.push(hub)
                }
            })

            this.setState({
                hubs: cleanHubs
            })
        })
    }


    render() {
        //let hubsOnMap = null

        const style = {
            width: '100%',
            height: '100%'
        }
        const containerStyle = {
            position: 'relative',  
            width: '100%',
            height: '100%'
        }

        // TODO: FIX THIS
        // Get Hubs that have double + Lat / Long
        // hubsOnMap = this.state.hubs.map((hub, key) => {
        //     if (hub.double.)
        //     return (
        //         <Marker name={'Current location'} />
        //     )
        // })

        return (
            <div className="Map">
                <Gmap containerStyle={containerStyle} style={style} google={this.props.google} zoom={14}>
 
                    <Marker name={'Current location'} />

                </Gmap>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        hubs: state.hub.hubs
    }
}
export default GoogleApiWrapper({
    apiKey: ('AIzaSyDBZKxfGPq6UpL9JJepUzSTHK7xi9-N-_Q')
})(connect(mapStateToProps)(Map))