import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LoadingSpinner = () => {
	return (
		<FontAwesomeIcon icon="spinner-third" size="lg" spin />
	)
}

export default LoadingSpinner
