import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import * as Templates from '../../store/Api/Echolo/Template'

import HubTemplateList from '../../Components/HubTemplates/HubTemplateList'

import SectionHeader from '../../Components/Shared/SectionHeader'
import LoadingSpinner from '../../Components/Shared/LoadingSpinner'

class HubTemplates extends Component {
    state = {
        templates: [],
        loading: true
    }

    getAllProfiles = () => {
        Templates.all(this.props.currentApp, this.props.currentUser).then(templates => {
            this.setState({
                templates: templates,
                loading: false
            })
            console.log("Templates: ", templates)
        }).catch(err => {
            console.log(err)
            this.setState({
                loading: false
            })
        })
    }

    componentDidMount = () => {
        this.getAllProfiles()
    }

    render() {
        let breadcrumb = [{name: 'Hubs', path: 'hubs'}, {name: 'Hub Templates', path: 'hub/templates'}]
        return (
            <div>
                <SectionHeader breadcrumb={breadcrumb} icon="brackets" title="Hub Templates">
                    <div className="row">
                        <div className="col-4">
                            
                        </div>
                        <div className="col-8 text-right">
                            <Link className="btn btn-success" to="/hub/templates/new"><FontAwesomeIcon icon="plus-square" /> New Template</Link>
                        </div>
                    </div>
                </SectionHeader>
                <div className="row">
                    <div className="col-12">
                        <section>
                            {this.state.loading ? <LoadingSpinner /> : <HubTemplateList templates={this.state.templates} />}
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        selectedHub: state.hub.selectedHub
    }
}
export default connect(mapStateToProps)(HubTemplates)
