import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Actions from '../store/actions'
import { Link } from 'react-router-dom'

import { currentYear } from '../Helpers/DateHelper'
import ServiceStatusBadge from '../Helpers/ServiceStatus/ServiceStatusBadge'

import TopMenu from './menus/TopMenu'
import LeftMenu from './menus/LeftMenu'
import VersionTag from './VersionTag'

const MainLayout = (props) => {

	let locationPath = props.children.props.location.pathname

	return (
		<div className="MainLayout">
			<div className="LeftMenu d-flex">
				<div className="LeftMenuHeader text-center">
					<Link to="/dashboard"><img src="/images/echolo-logo-h50.png" alt="ECHOLO, LLC" /></Link>
				</div>

				<div className="LeftMenuOptions">
					<LeftMenu locationPath={locationPath} />
					<div className={"VersionDisplay"}><VersionTag classes={"VersionTag badge badge-pill text-muted"} /></div>
				</div>
			</div>

			<div className="ContentArea">
				<div className="TopMenu">
					<TopMenu locationPath={locationPath} />
				</div>

				<div className="ContentAreaContent">

					<div className="container-fluid ContentAreaRouterView">

						<div className="row">
							<div className="col-12">
								{props.children}
							</div>
						</div>

						<footer>
							<div className="row">
								<div className="col-6">
									<p className="CopyrightText">Hand-crafted &amp; Designed by Echolo, LLC in the USA <span className="FooterFlag"><FontAwesomeIcon icon="flag-usa" /></span>, Copyright &copy;{currentYear()}</p>
								</div>
								<div className="col-6 text-right">
									<ServiceStatusBadge />
								</div>
							</div>
						</footer>
					</div>

				</div>
                
			</div>

		</div>
	)
}

const mapStateToProps = state => {
	return {
		currentUser: state.user.currentUser,
		currentApp: state.app.currentApp
	}
}

const mapDispactToProps = dispatch => {
	return {
		setCurrentUser: (user) => dispatch({ type: Actions.SET_CURRENT_USER, payload: user })
	}
}

export default connect(mapStateToProps, mapDispactToProps)(MainLayout)

