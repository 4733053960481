import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'


class Logout extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    logout = async () => {
        try {
            await Auth.signOut()
            console.log("Logging out...")
            this.props.setCurrentUser({})
            this.props.setCurrentApp({})
            this.props.setHubList([])
            this.props.cookies.set('currentUser', null, { path: '/' })
            localStorage.removeItem('currentUser')
            localStorage.removeItem('currentapp')
        } catch (error) {
            console.log('error signing out: ', error)
        }
    }

    render() {
        return (
            <a title="Sign Out" className="Logout topNavItem rounded" onClick={this.logout} href="/"><FontAwesomeIcon icon="sign-out" /></a>
        )
    }
}
const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentApp: (apps) => dispatch({ type: Actions.SET_CURRENT_APPLICATION, payload: apps }),
        setCurrentUser: (user) => dispatch({ type: Actions.SET_CURRENT_USER, payload: user }),
        setHubList: (hubs) => dispatch({ type: Actions.SET_HUB_LIST, payload: hubs })
    }
}

export default withCookies(connect(mapStateToProps, mapDispactToProps)(Logout))