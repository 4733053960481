import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ServiceSummary } from '../../store/Api/StatusPage/Status'

import SectionHeader from '../../Components/Shared/SectionHeader'
import ServiceStatusIconHelper from '../../Helpers/ServiceStatus/ServiceStatusIconHelper'
import ServiceStatusIncidentsItem from '../../Helpers/ServiceStatus/ServiceStatusIncidentsItem'
import ServiceStatusMaintenanceItem from '../../Helpers/ServiceStatus/ServiceStatusMaintenanceItem'

class ServiceStatus extends Component {
    state = {
        status: null,
        summary: null,
        isIncident: false,
        incidents: [],
        isMaintenance: false,
        scheduled_maintenances: [],
        components: []
    }

    timer = null
    getStatusUpdates = () => {
        ServiceSummary().then(res => {
            // res.status.indicator = [major, minor, none]
            // incidents = Array
            let isIncident = res.status.indicator !== "none" ? true : false
            let isMaintenance = res.scheduled_maintenances.length > 0 ? true : false
            this.setState({
                status: res.incidents,
                summary: res.status,
                isIncident: isIncident,
                incidents: res.incidents,
                scheduled_maintenances: res.scheduled_maintenances,
                components: res.components,
                isMaintenance: isMaintenance
            })


            // Only start if there is an incident
            if (isIncident) {
                this.timer = setInterval(()=> this.getStatusUpdates(), 60000*2)
            }
            
        })
    }

    componentDidMount = () => {
        this.getStatusUpdates()
    }

    componentWillUnmount = () => {
        clearInterval(this.timer)
        this.timer = null
    }

    render() {
        const { components, isIncident, incidents, isMaintenance, scheduled_maintenances } = this.state
        let componentsList = ""

        componentsList = components.map((component, key) => {
            return (
                <div key={key}>
                    <p><ServiceStatusIconHelper status={component.status} /> {component.name}</p>
                </div>
            )
        })

        let incidentList = []
        if (isIncident) {
            incidentList = incidents.map((incident, key) => {
                return (
                    <ServiceStatusIncidentsItem key={key} incident={incident} />
                )
            })
        }
        
        let maintenanceList = []
        if (isMaintenance) {
            maintenanceList = scheduled_maintenances.map((maintenance, key) => {
                return (
                    <ServiceStatusMaintenanceItem key={key} maintenance={maintenance} />
                )
            })
        }

        return (
            <div className="ServiceStatus">
                <SectionHeader icon="shield-check" title="Echolo Service Status"></SectionHeader>
                <div className="row justify-content-center">
                    <div className="col-6">
                        <section>
                            <h4>Current Service Status</h4>
                            {componentsList}
                        </section>
                    </div>

                    {isIncident ? 
                        <div className="col-6">
                            <section>
                                <h4>Active Incident</h4>
                                {incidentList}
                            </section>
                        </div>
                    : null}

                </div>

                {isMaintenance ?
                <div className="row">
                    <div className="col-12">
                        <section>
                            <h4>Active / Upcoming Maintenance</h4>
                            {maintenanceList}
                        </section>
                    </div>
                </div>
                : null}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp
    }
}

export default connect(mapStateToProps)(ServiceStatus)
