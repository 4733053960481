import React, { Component } from 'react'
import { connect } from 'react-redux'

import HubShowSubMenu from '../../Layouts/menus/HubShowSubMenu'
import SectionHeader from '../../Components/Shared/SectionHeader'
import LoadingSpinner from '../../Components/Shared/LoadingSpinner'
import TelemetryList from '../../Components/Telemetry/TelemetryList'
import HubG1Control from '../../Components/Hubs/HubG1Control'


class HubTelemetry extends Component {
    state = {
        loading: true
    }  

    componentDidMount = () => {
    }

    telemLoading = (status) => {
        this.setState({
            loading: status
        })
    }

    render() {
        let breadcrumb = [{name: 'Hubs', path: 'hubs'}, {name: this.props.match.params.hubId, path: 'hubs/' + this.props.match.params.hubId}, {name: "Telemetry", path: 'hubs/' + this.props.match.params.hubId + '/telemetry'}]
        let { sku } = this.props.selectedHub
        return (
            <>
                <SectionHeader breadcrumb={breadcrumb} icon="router" title="Telemetry">
                    <div className="row">
                        <div className="col-4">
                            
                        </div>
                        <div className="col-8 text-right">
                            {sku ?
                            <>
                                {this.props.selectedHub.sku.includes("EL-G1") ? <HubG1Control hub={this.props.selectedHub} /> : null }
                            </>
                            : null}
                        </div>
                    </div>
                </SectionHeader>
                
                <HubShowSubMenu location={this.props.location.pathname} hubId={this.props.selectedHub.hubId} />

                <div className="row">
                    <div className="col-12">
                        <section>
                            {this.state.loading ? <LoadingSpinner /> : null}
                            <TelemetryList searchQueryParams={"?fields.packet.hubId=" + this.props.selectedHub.hubId} offsetLimit="50" polling={true} pollingTimer={60100*2} loadingHandler={this.telemLoading} />
                        </section>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        hubs: state.hub.hubs,
        selectedHub: state.hub.selectedHub
    }
}

export default connect(mapStateToProps)(HubTelemetry)
