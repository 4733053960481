import React from 'react'

const GatewayListItem = (props) => {
	return (
		<tr>
			<td>{props.gateway.gatewayId}</td>
			<td>{props.gateway.key}</td>
			<td>{props.gateway.sku}</td>
		</tr>
	)
}

export default GatewayListItem
