import axios from 'axios'


let appHeaders = (user) => {
	return {
		headers: {
			'X-User-Token': user.authentication_token,
			'X-User-Email': user.email
		}
	}
    
}

let tokenHeaders = (app, user) => {
	return {
		headers: {
			'x-api-key': user.xapi_key,
			'appId': app.appId,
			'token': app.token,
		}
	}
}


export const get = (searchArray = [], currentApp, offsetLimit, user) => {
	if (!offsetLimit) offsetLimit = 50
	return new Promise((resolve, reject) => {
		axios.post('/search', { 'search': searchArray, 'limit': offsetLimit, 'fromDate': 'now-1d' }, tokenHeaders(currentApp, user)).then(data => {
			resolve(data.data)
		}).catch(e => {
			reject(e)
		})
            
	})
    
}