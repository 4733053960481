import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'

const UserEditForm = (props) => {
	const userValues = {
		first_name: props.user.first_name || '',
		last_name: props.user.last_name || '',
		email: props.user.email
	}

	const userUpdateLoading = false


	const { register, errors, getValues, handleSubmit } = useForm()

	const [editUser, setEditUser] = useState(userValues)
	const [loading, setLoading] = useState(userUpdateLoading)


	const sendUpdate = async (data) => {
		// Check for changes
		if (editUser === userValues) {
			// Do not submit
			return false
		}
		setLoading(true)

		// Filter blank Password
		if (data.password === '' || data.password === null) {
			delete data.password
			delete data.confirm_password
			delete data.current_password
		}

		let user = await Auth.currentAuthenticatedUser()
		let result = await Auth.updateUserAttributes(user, {
			name: data.first_name,
			family_name: data.last_name
		})

		if (data.current_password && data.password) {
			// Changing password
			await Auth.changePassword(user, data.current_password, data.password)
		}

		if (result === 'SUCCESS') {
			let { attributes } = await Auth.currentAuthenticatedUser()

			let configureUser = {
				first_name: attributes.name,
				last_name: attributes.family_name,
				email: attributes.email,
				authentication_token: attributes['custom:el-APItoken'],
				rtls_flag: attributes['custom:rtls_flag'],
				telemetry_flag: attributes['custom:telemetry_flag']
			}

			props.setCurrentUser(configureUser)
			setTimeout(() => setLoading(false), 1000)
			props.redirectHandler('true')
		} else {
			setTimeout(() => setLoading(false), 1000)
			props.redirectHandler('false')
		}
	}

	return (
		<div>
			<form onSubmit={handleSubmit(data => sendUpdate(data))}>
				<div className="form-group">
					<label htmlFor="first_name">First Name</label>
					<input value={editUser.first_name} 
						onChange={e => setEditUser({ ...editUser, first_name: e.target.value })}
						type="text" 
						className="form-control" 
						id="first_name"
						name="first_name" 
						ref={register({ required: true })} />
					{errors.first_name && <p className="text-danger">Your First Name is required.</p>}
				</div>

				<div className="form-group">
					<label htmlFor="last_name">Last Name</label>
					<input value={editUser.last_name} 
						onChange={e => setEditUser({ ...editUser, last_name: e.target.value })}
						type="text" 
						className="form-control" 
						id="last_name" 
						name="last_name"
						ref={register({ required: true })} />
					{errors.last_name && <p className="text-danger">Your Last Name is required.</p>}
				</div>

				<div className="form-group">
					<label htmlFor="email">Email Address</label>
					<input disabled value={editUser.email} 
						onChange={e => setEditUser({ ...editUser, email: e.target.value })}
						type="email" 
						className="form-control" 
						id="email" 
						name="email"
						ref={register({ required: true })} />
					{errors.email && <p className="text-danger">Email is required.</p>}
				</div>

				<hr />

				<h5>Change your password:</h5>
				<p>To keep your current password, leave the fields below blank.</p>
				<div className="form-group">
					<label htmlFor="current_password">CURRENT Password</label>
					<input type="password" 
						className="form-control" 
						id="current_password" 
						name="current_password"
						ref={register({
							required: false,
							validate: {
								validateLength: (value) => {
									if (value !== '') {
										return value.length >= 6 || 'Your password must be at least 6 characters'
									}
								}
							}
						})} />
                    
					{errors.current_password && <p className="text-danger">{errors.current_password.message}</p>}
				</div>

				<div className="form-group">
					<label htmlFor="password">NEW Password</label>
					<input type="password" 
						className="form-control" 
						id="password" 
						name="password"
						aria-describedby="passwordHelp"
						ref={register({
							required: false,
							validate: {
								validateLength: (value) => {
									if (value !== '') {
										return value.length >= 6 || 'Your password must be at least 6 characters'
									}
								}
							}
						})} />
					<small id="passwordHelp" className="form-text text-muted">Minimum of 6 characters.</small>
					{errors.password && <p className="text-danger">{errors.password.message}</p>}
				</div>

				<div className="form-group">
					<label htmlFor="confirm_password">Confirm NEW Password</label>
					<input type="password" 
						className="form-control" 
						id="confirm_password" 
						name="confirm_password"
						ref={register({
							required: false,
							validate: {
								matchesPreviousPassword: (value) => {
									const { password } = getValues()
									return password === value || 'Passwords should match!'
								},
								validateLength: (value) => {
									if (value !== '') {
										return value.length >= 6 || 'Your password must be at least 6 characters'
									}
								}
							}
						})} />
					{errors.confirm_password && <p className="text-danger">{errors.confirm_password.message}</p>}
				</div>
				<div className="text-right">
					{loading ? <>
						<button className="btn btn-sm btn-primary" disabled="disabled"><span><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Saving...</span></button>
					</> : <button type="submit" className="btn btn-sm btn-primary">Save</button>}
				</div>
			</form>
		</div>
	)
}

const mapDispactToProps = dispatch => {
	return {
		setCurrentUser: (user) => dispatch({ type: Actions.SET_CURRENT_USER, payload: user })
	}
}

export default connect(null, mapDispactToProps)(UserEditForm)
