import React, { Component } from 'react'

class DevicePacket extends Component {
	render() {
		let tableRow = []
		if (this.props.packetData) {
			let d = Object.keys(this.props.packetData)
			tableRow = d.map((key, i) => {
				return (
					<tr key={i}>
						{this.props.packetData[key] ? 
							<>
								<th scope="row">{key}</th>
								<td>{this.props.packetData[key]}</td>
							</>
							: null}
					</tr>
				)
			})
		} else {
			tableRow = (<p>This device does not have a profiled packet.</p>)
		}
        
		return (
			<>{tableRow}</>
		)
	}
}

export default DevicePacket
