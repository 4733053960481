import * as actionType from '../../actions'

import { loadState } from '../../localStorage'

let initState = {
	currentApp: {},
	applications: [],
	applicationNumbers: {}
}

const LoadedState = loadState()
if (LoadedState) {
	initState = LoadedState.app || initState
}

const ApplicationReducer = (state = initState, action) => {
	switch(action.type) {
	case actionType.SET_CURRENT_APPLICATION:
		return {
			...state,
			currentApp: action.payload
		}
	case actionType.SET_CURRENT_USER_APPLICATIONS:
		return {
			...state,
			applications: action.payload
		}
	case actionType.SET_APPLICATION_NUMBERS:
		return {
			...state,
			applicationNumbers: action.payload
		}
	default:
		return state
	}
}

export default ApplicationReducer
