import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'

import * as UrlParamHelper from '../../Helpers/UrlParams'
import * as TelemetryHistoryHelper from '../../Helpers/TelemetryHistoryHelper'

import SectionHeader from '../../Components/Shared/SectionHeader'
import TelemetryList from '../../Components/Telemetry/TelemetryList'
import TelemetryChart from '../../Components/Telemetry/TelemetryChart'
import TelemetrySearchHistory from '../../Components/Telemetry/TelemetrySearchHistory'
import TelemetrySearchRemoveBtn from '../../Components/Telemetry/TelemetrySearchRemoveBtn'
import TelemetrySearchForm from '../../Components/Telemetry/TelemetrySearchForm'

class Telemetry extends Component {
    state = {
        dataSetLoading: true,
        dataSetRefresh: true,
        telemData: [],
        searchQueryParams: "",
        searchHistory: JSON.parse(localStorage.getItem('searchHistory')) || [],
        searchFormInput: "",
        locationSearch: "",
        chartLoading: true
    }

    // This will be used to poll for new data.
    timer = null

    querySearch = (currentHistory) => {
        // TODO: List Fixed / Need to fix CHART

        // Set state
        this.setState({
            searchHistory: currentHistory || this.state.searchHistory,
            dataSetLoading: false,
            dataSetRefresh: false,
            locationSearch: this.props.location.search
        })

    }

    componentDidUpdate(prevProps, prevState) {
        // Get current params IF ANY
        // Will return [] if none
        let currentUrlParams = UrlParamHelper.getParams(this.props.location.search)

        // If we are on the same page and the search values change, this will be met
        if (this.props.location.search !== prevProps.location.search) {

            this.setState({
                dataSetLoading: true,
                searchQueryParams: currentUrlParams
            })

        }

        // This will fire only if the state.searchQueryParams are changed.
        // 1) Will fire on page load
        // 2) Will fire when on the same page and the location.search values change.
        if (this.state.searchQueryParams !== prevState.searchQueryParams) {
            if (this.props.currentApp) {

                // Set current history if we have any
                // will be [] if none
                let currentHistory = this.state.searchHistory

                // Check to see if we need to update History at all
                // This could be a first time load OR no params
                if (currentUrlParams.length > 0){
                    // We have some history to add
                    // currentUrlParams[0] => [{"key":"value"}]

                    // Add a new Item to History if it's not a duplicate
                    currentHistory = TelemetryHistoryHelper.add(currentHistory, currentUrlParams[0])
                }


                // Make a search based on the new searchQueryParams
                this.querySearch(currentHistory)
            }

        }
    }

    componentDidMount() {
        // Set the state to force a search AKA 'componentDidUpdate'
        let urlParams = UrlParamHelper.getParams(this.props.location.search)

        this.setState({
            searchQueryParams: urlParams,
            locationSearch: this.props.location.search
        })

        // Start polling for new data
        this.timer = setInterval(()=> this.querySearch(null, "50"), 30000)
        // clearInterval(this.timer)

    }

    componentWillUnmount = () => {
        clearInterval(this.timer)
        this.timer = null
    }

    // Used to reset the state
    removeHistoryHandler(e) {
        this.setState({
            searchHistory: []
        })
    }

    searchFormHandler = (e, searchInput) => {

        //const history = useHistory()
        // Stop the form from submitting
        e.preventDefault()

        // Check for valid this.state.searchFormInput
        if (searchInput) {
            // Create a new object with the search data
            let newObj = {}
            let newArr = []
            let splitStr = searchInput.split(":")
            newObj[splitStr[0]] = splitStr[1].replace(/"/g, "").replace(/'/g, "").replace(/ /g, "")
            // 'newObj' => {anyting:"String"}
            newArr.push(newObj) // Push into the new Array

            // Set the new search data to the 'searchQueryParams'
            this.setState({
                searchQueryParams: newArr
            })

            let urlParams = UrlParamHelper.encode(newObj)

            let location = {
                pathname: '/telemetry',
                search: '?' + urlParams
            }
            this.props.history.push(location)

        } else {
            this.setState({
                searchQueryParams: []
            })
        }
    }


    resetQueryParams = () => {
        let location = {
            pathname: '/telemetry'
        }
        this.props.history.push(location)

        this.setState({
            searchQueryParams: [],
            searchFormInput: "",
            dataSetLoading: true,
            dataSetRefresh: true,
            locationSearch: ""
        })
    }

    refreshLoadingHandler = (state) => {
        this.setState({
            dataSetRefresh: state
        })
    }

    chartLoadingHandler = (state) => {
        this.setState({
            chartLoading: state
        })
    }

    render() {


        return (
            <div>
                <SectionHeader icon="terminal" title="Telemetry Console"></SectionHeader>
                {this.props.currentApp ? 
                <>
                    <div className="row TelemetrySearchFormSection">
                        <div className="col-12">
                            <section>
                                <div className="row">
                                    <div className="col-12">
                                        <TelemetrySearchForm initSearch={this.state.searchQueryParams} submitHandler={this.searchFormHandler} resetQueryParams={this.resetQueryParams} />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7">
                            <section className="TelemetryChart">
                                <div className="row">
                                    <div className="col-12">
                                        <h4>Activity {this.state.chartLoading ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  : null}</h4>
                                    </div>
                                    <div className="col-12 chartArea">
                                        <TelemetryChart loading={this.chartLoadingHandler} searchQueryParams={this.state.locationSearch} currentUser={this.props.currentUser} currentApp={this.props.currentApp} offsetLimit="50" polling={true} pollingTimer={121000} />
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div className="col-5">
                            <section className="TelemetrySearchHistorySection">
                                <h4>Console History</h4>
                                <div className="row">
                                    <div className="col-12">
                                        {this.state.searchHistory.length > 0 ? null : <p>No Search History Yet!</p>}
                                        <TelemetrySearchHistory telemSearchHistory={this.state.searchHistory} />
                                    </div>
                                </div>
                                <div className="row resetArea align-items-end">
                                    <div className="col-12 text-right">
                                        {this.state.searchHistory.length > 0 ? <TelemetrySearchRemoveBtn removeHistory={e => this.removeHistoryHandler(e)} /> : null}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <section>
                        <div className="row">
                            <div className="col-6">
                                <h4>Live Telemetry</h4>
                            </div>
                            <div className="col-6 text-right">
                                <button onClick={e => this.resetQueryParams(e)} className="btn btn-outline-info btn-sm" type="button">
                                    {this.state.dataSetRefresh || this.state.dataSetLoading ? 
                                        <>
                                            <span><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading new data...</span>
                                        </>
                                    : <span><FontAwesomeIcon icon="undo" /> Refresh</span>}
                                </button>
                                
                            </div>
                        </div>
                        {this.state.dataSetRefresh || this.state.dataSetLoading ? 
                            <span><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading new data...</span> : null}
                        <TelemetryList polling={true} pollingTimer={120000} loadingHandler={this.refreshLoadingHandler} offsetLimit="50" currentApp={this.state.currentApp} currentUser={this.props.currentUser} searchQueryParams={this.state.locationSearch} />
                    </section>
                </> : <p>Select an application first.</p>}
                
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentUserApplications: state.app.applications,
        currentApp: state.app.currentApp
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentApp: (apps) => dispatch({ type: Actions.SET_CURRENT_APPLICATION, payload: apps }),
        setCurrentUserApplications: (apps) => dispatch({ type: Actions.SET_CURRENT_USER_APPLICATIONS, payload: apps }),
        setHubList: (hubs) => dispatch({ type: Actions.SET_HUB_LIST, payload: hubs })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(Telemetry)
