import React from 'react'
import { Link } from 'react-router-dom'

const HubsSubMenu = ({ location, hubCounts }) => {
	return (
		<div className="row SectionSubHeader">
			<div className="col-12">
				<ul>
					<li><Link className={location === '' ? 'active' : ''} to={'/hubs'}>Online [{hubCounts.online}]</Link></li>
					<li><Link className={location.includes('?offline=true') ? 'active' : ''} to={'/hubs?offline=true'}>Offline [{hubCounts.offline}]</Link></li>
				</ul>
			</div>
		</div>
	)
}

export default HubsSubMenu