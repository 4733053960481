import * as actionType from '../../actions'
import { loadState } from '../../localStorage'

let initState = {
	currentUser: {},
	currentUserLocation: {},
	sessionUsername: ''
}

const LoadedState = loadState()
if (LoadedState) {
	initState = LoadedState.user || initState
}

const UserReducer = (state = initState, action) => {
	switch(action.type) {
	case actionType.SET_CURRENT_USER:
		return {
			...state,
			currentUser: action.payload
		}
	case actionType.SET_SESSION_USERNAME:
		return {
			...state,
			sessionUsername: action.payload
		}
	default:
		return state
	}
}

export default UserReducer
