import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { currentYear } from '../Helpers/DateHelper'
import ServiceStatusBadge from '../Helpers/ServiceStatus/ServiceStatusBadge'

import VersionTag from './VersionTag'

const BasicLayout = ({ children }) => {
	return (
		<div className="container-fluid BasicContainer">
			<div className="row FullHeight">
				<div className="col-4 LogoBackground">
					<div className="Logo">
						<img src="/images/echolo-logo-white-w200.png" alt="Echolo, LLC Logo" />
					</div>
					<div className="welcomeMsg">
						<h4>Welcome to Echolo IoT</h4>
						<p>The Echolo Internet of Things Platform delivers your data from the physical world to the digital one.</p>
						<ul className="ServiceLinks">
							<li><FontAwesomeIcon icon="shield-check" /> <a target="_blank" rel="noopener noreferrer" href="https://echoloiot.statuspage.io/">Service Status</a></li>
							<li><FontAwesomeIcon icon="question-circle" /> <a target="_blank" rel="noopener noreferrer" href="https://support.echolo.io/">Customer Support</a></li>
						</ul>
					</div>
					<div className="BasicLayoutFooter">
						<p>ECHOLO Internet of Things Platform Version <VersionTag/></p>
						<p>&copy; Copyright {currentYear()}, ECHOLO, LLC</p>
					</div>
				</div>

				<div className="col-8 BasicLayoutMainBox">
					<div className="mx-auto login-box">
						{children}
					</div>

					<div className="row justify-content-center BasicLayoutMainBoxFooter">
						<div className="col-6 text-center">
							<ServiceStatusBadge />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


export default BasicLayout
