import * as actionType from '../../actions'
import { loadState } from '../../localStorage'

let initState = {
	devices: [],
	selectedDevice: {}
}

const LoadedState = loadState()
if (LoadedState) {
	initState = LoadedState.device || initState
}

const HubReducer = (state = initState, action) => {
	switch(action.type) {
	case actionType.SET_DEVICE_LIST:
		return {
			...state,
			devices: action.payload
		}
	case actionType.SET_SELECTED_DEVICE:
		return {
			...state,
			selectedDevice: action.payload
		}
	default:
		return state
	}
}

export default HubReducer
