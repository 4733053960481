import axios from 'axios'

const headers = () => {
	let user = JSON.parse(localStorage.getItem('currentUser'))
	axios.defaults.headers.common['X-User-Token'] = user.authentication_token
	axios.defaults.headers.common['X-User-Email'] = user.email
}

export const all = (app) => {
	headers()
	return new Promise((resolve, reject) => {
		axios.get('/applications/' + app.app_identifier + '/brokers').then(res => {
			resolve(res.data)
		}).catch(e => {
			reject(e)
		})
	})
}
