import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Actions from '../store/actions'

import * as User from '../store/Api/Echolo/User'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Register = ({ setSessionUsername }) => {
	const logValues = {
		email: '',
		password: '',
		first_name: '',
		last_name: '',
		rtls_flag: false,
		telemetry_flag: false,
		apiKey: '',
	}

    const { register, handleSubmit, formState: { errors } } = useForm({reValidateMode: "onBlur"})


	const [userRegister, setUserRegister] = useState(logValues)
	const [confirmPage, setConfirmPage] = useState(false)
	const [showalert, setShowalert] = useState(null)


    const resgisterHandler = async (userData) => {
        try {
			Auth.signUp({
				email: userData.email,
				username: userData.email,
				password: userData.password,
				attributes: {
					name: userData.first_name,
					family_name: userData.last_name,
					'custom:rtls_flag': 'false',
					'custom:telemetry_flag': 'false',
					'custom:xapi_key': userData.apiKey,
				}
			}).then(async (cognito) => {
				setSessionUsername(cognito.user.username)
				try {
					await User.create({ userId: cognito.userSub })
					setConfirmPage(true)
				} catch (error) {
					setShowalert(<div className="alert alert-danger" role="alert">
						I am sorry! There was an issue. Please contact support: support@echolo.io
					</div>)
					setConfirmPage(false)
				}
			}).catch(error => {
				setShowalert(<div className="alert alert-danger" role="alert">
					I am sorry! There was an issue. {error.message}
				</div>)
			})
            
		} catch (error) {
			// Cognito Error
			console.log('error signing up:', error)
			setShowalert(<div className="alert alert-danger" role="alert">
                            I am sorry! There was an issue. {error.message}
			</div>)
		}
	}

	let redirect = null
	if (confirmPage) redirect = <Redirect to="/register/confirm" />

    return (
        <div className="LoginForm">
            {showalert}
            <h3 className="text-center text-dark">Register</h3>
            <p className="text-center text-subtext">Create a new account with Echolo IoT.</p>
            {redirect}
            <form onSubmit={handleSubmit(resgisterHandler)}>

                <div className="form-group">
					{errors.email && <p className="text-danger">Email is required.</p>}
                    <label htmlFor="email">Email Address (<span className={'text-danger'}>*</span>):</label>
                    <input 
                    value={userRegister.email}
                    name="email"
                    onChange={e => setUserRegister({ ...userRegister, email: e.target.value })}
                    placeholder="Email Address"
                    ref={register({ required: true })}
                    type="email" className="form-control form-control-lg" id="email" />
                </div>

				<div className="form-group">
					{errors.first_name && <p className="text-danger">Your First Name is required.</p>}
					<label htmlFor="first_name">First Name (<span className={'text-danger'}>*</span>):</label>
					<input 
						value={userRegister.first_name}
						name="first_name"
						onChange={e => setUserRegister({ ...userRegister, first_name: e.target.value })}
						placeholder="First Name"
						ref={register({ required: true })}
						type="text" className="form-control form-control-lg" id="first_name" />
				</div>

				<div className="form-group">
					{errors.last_name && <p className="text-danger">Your Last Name is required.</p>}
					<label htmlFor="first_name">Last Name (<span className={'text-danger'}>*</span>):</label>
					<input 
						value={userRegister.last_name}
						name="last_name"
						onChange={e => setUserRegister({ ...userRegister, last_name: e.target.value })}
						placeholder="Last Name"
						ref={register({ required: true })}
						type="text" className="form-control form-control-lg" id="last_name" />
				</div>

                <div className="form-group">
					{errors.password && errors.password.type === "pattern" && (
						<div className="error">Your password must be at least 8 characters long with a minimum of one uppercase letter and one special character.</div>
					)}
					{errors.password && <p className="text-danger">A password is required.</p>}
                    <label htmlFor="first_name">Password (<span className={'text-danger'}>*</span>):</label>
                    <input 
                    value={userRegister.password}
                    name="password"
                    onChange={e => {
                        setUserRegister({ ...userRegister, password: e.target.value })
                    }}
                    placeholder="Password"
                    ref={register({ required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ })}
                    type="password" className="form-control form-control-lg" id="password" />
					<small id="emailHelp" className="form-text text-muted">Your password must be at least
						<strong>8 characters long</strong> with a minimum of <strong>one uppercase letter</strong> and <strong>one special character (!@#$%^&amp;*)</strong>.</small>
                </div>

				<hr />

				<div className="form-group">
					{errors.apiKey && <p className="text-danger">Your API Key is required to register.</p>}
					<label htmlFor="apiKey">API Key (<span className={'text-danger'}>*</span>):</label>
					<small id="apiKeyHelp" className="form-text text-muted pb-1">When your account was created, you were provided an API Key, please enter that here.</small>
					<input
						value={userRegister.apiKey}
						name="apiKey"
						onChange={e => setUserRegister({ ...userRegister, apiKey: e.target.value })}
						placeholder="API Key"
						ref={register({ required: true })}
						type="text" className="form-control form-control-lg" id="apiKey" />
					<small id="apiKeyHelp2" className="form-text text-muted pb-1">If you dont have an API Key please contact support <a href={'https://echoloiot.atlassian.net/servicedesk/customer/portals'}>Echolo Customer Support</a>.</small>
				</div>

				<div className="row">
					<div className="col-12 pt-3">
						<button type="submit" className="btn btn-outline-primary btn-lg btn-block">Register <FontAwesomeIcon icon="chevron-right" /></button>
					</div>
					<div className="col-12">
						<p className="text-size-medium">Already have an account? <Link to="/">Signin here</Link>.</p>
					</div>
				</div>
			</form>
		</div>
	)
    
}

const mapDispactToProps = dispatch => {
	return {
		setSessionUsername: (username) => dispatch({ type: Actions.SET_SESSION_USERNAME, payload: username })
	}
}
export default connect(null, mapDispactToProps)(Register)
