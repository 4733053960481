import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, combineReducers } from 'redux'
import { Provider } from 'react-redux'

import { BrowserRouter} from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import axios from 'axios'

import UserReducer from './store/Models/User/UserReducer'
import ApplicationReducer from './store/Models/Application/ApplicationReducer'
import HubReducer from './store/Models/Hub/HubReducer'
import DeviceReducer from './store/Models/Device/DeviceReducer'

import { saveState } from './store/localStorage'

const mainReducer = combineReducers({
	user: UserReducer,
	app: ApplicationReducer,
	hub: HubReducer,
	device: DeviceReducer
})
const store = createStore(mainReducer)

store.subscribe(() => {
	saveState(store.getState())
})

axios.defaults.baseURL = 'https://api.echolo.io/v3'
//axios.defaults.baseURL = 'http://localhost:3001'

const app = (
	<BrowserRouter>
		<Provider store={store}>
			<CookiesProvider>
				<App />
			</CookiesProvider>
		</Provider>
	</BrowserRouter>
)


ReactDOM.render(
	app,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
