import { Application } from './interfaces/application.interface'
interface HeaderUserInterface {
    xapi_key: string
}
export class HeadersEcholo {
    constructor(
        public user: HeaderUserInterface
    ) {
    }

    xapiOnly = () => {
        return {
            headers: {
                'x-api-key': this.user.xapi_key,
            }
        }
    }

    tokenHeaders = (app: Application) => {
        return {
            headers: {
                'x-api-key': this.user.xapi_key,
                'appId': app.appId,
                'token': app.token,
            }
        }
    }

    basicHeaders = (app: Application) => {
        return {
            headers: {
                'x-api-key': this.user.xapi_key,
                'appId': app.appId,
                'secret': app.secret,
            }
        }
    }

}
