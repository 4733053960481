export interface APIErrorInterface {
    status: number
    error: string
    data: any
}
export class APIError {
    constructor(
        public error: APIErrorInterface
    ) {
    }
}
