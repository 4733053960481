import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

class SectionHeader extends Component {

	render() {
		let crumbs = ''
		if (this.props.breadcrumb) {
			crumbs = this.props.breadcrumb.map((name, key) => {
				return (
					<span key={key}> / <Link to={'/' + name.path}>{name.name}</Link></span>
				)
			})
		}

		return (
			<div className="PageHeader">
				<div className="row">
					<div className="col-8">
						<h2 className="PageHeaderTitle"><FontAwesomeIcon icon={this.props.icon} /> {this.props.title}</h2>
						<p className="PageHeaderBreadcrumb align-top"><Link to="/dashboard"><FontAwesomeIcon icon="home" /></Link> 
							{this.props.breadcrumb ? crumbs : ' / ' + this.props.title}</p>
					</div>
					<div className="col-4">
						{this.props.children}
					</div>
				</div>
			</div>
		)
	}
}

export default SectionHeader
