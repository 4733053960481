import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'

import * as ApiHub from '../../store/Api/Echolo/Hub'

import DeviceList from '../../Components/Devices/DeviceList'
import SectionHeader from '../../Components/Shared/SectionHeader'
import HubShowSubMenu from '../../Layouts/menus/HubShowSubMenu'
import LoadingSpinner from '../../Components/Shared/LoadingSpinner'
import TelemetryChart from '../../Components/Telemetry/TelemetryChart'
import HubG1Control from '../../Components/Hubs/HubG1Control'

import * as DateHelper from '../../Helpers/DateHelper'
import * as HubHelper from '../../Helpers/HubHelper'

import { Application } from '../../Interfaces/application.interface'
import { User } from '../../Interfaces/user.interface'
import { Hub } from '../../store/Api/Echolo/interfaces/hub.interface'
import {Device} from "../../Interfaces/device.interface";

type HubShowState = {
    hub?: Hub,
    hubLoading: boolean,
    hubDouble: any,
    chartLoading: boolean,
    devices?: Device[]
}

interface hubShowRender {
    children: React.ReactNode
}
class HubShow extends React.Component<{
    currentApp: Application,
    currentUser: User,
    match: any,
    selectedHub: Hub,
    setSelectedHub: any,
    location: any,
}, HubShowState> {

    state: HubShowState = {
        hubLoading: true,
        hubDouble: null,
        chartLoading: false
    }

    timer: ReturnType<typeof setInterval> | null = null

    stopTimer = () => {
        clearInterval(this.timer as ReturnType<typeof setInterval>)
    }
    startTimer = () => {
        this.timer = setInterval(()=> this.hubGet(), 60000)
    }

    hubGet = async () => {
        try {
            const hub: Hub = await ApiHub.get(this.props.currentApp, this.props.match.params.hubId, this.props.currentUser)
            const devices = await ApiHub.devices(this.props.currentApp, this.props.match.params.hubId, this.props.currentUser)
            this.setState({
                hub: hub,
                hubLoading: false,
                devices: devices
            })
            this.props.setSelectedHub(hub)
        } catch (error) {
            // Error State
            console.log("[ERROR]: ", error)
        }
    }

    componentWillUnmount() {
        this.stopTimer()
        this.timer = null
    }

    async componentDidMount() {
        if (this.props.match.params.hubId) {
            // Make API request
            if (this.props.match.params.hubId === this.props.selectedHub.hubId) {
                // already have the hub
                this.setState({
                    hub: this.props.selectedHub,
                    hubLoading: false
                })
            } else {
                await this.hubGet()
            }
            
            this.startTimer()
        }
    }

    chartLoadingHandler = (status) => {
        this.setState({
            chartLoading: status
        })
    }

    environmentSwitchHandler = async (env) => {
        let results = await ApiHub.update(this.props.currentApp, this.props.match.params.hubId, this.props.currentUser, { environment: env })
        this.setState({
            hub: { ...this.state.hub, environment: env } as Hub
        })
    }
    

    render() {
        let breadcrumb = [{name: 'Hubs', path: 'hubs'}, {name: this.props.match.params.hubId, path: 'hubs/' + this.props.match.params.hubId}]
        let { sku } = this.state.hub ? this.state.hub : { sku: 'N/A' }

        return (
            <div>
                <SectionHeader breadcrumb={breadcrumb} icon="router" title={this.props.match.params.hubId}>
                    <div className="row">
                        
                        <div className="col-12 text-right">
                            {!this.state.hubLoading ? 
                            <>
                                {sku ?
                                <>
                                    {(this.state.hub?.sku.includes("EL-G1") || this.state.hub?.sku.includes("EL-ILR-G1") ) ? <HubG1Control hub={this.state.hub} /> : null }
                                </> 
                                : null }
                            </>
                            : null}

                            <div className="pt-3">
                                <p className="mb-0"><small>Hub Environment:</small></p>
                                <div className="btn-group">
                                    <button onClick={() => this.environmentSwitchHandler('development')} className={this.state.hub?.environment === 'development' ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-outline-primary'}>Development</button>
                                    <button onClick={() => this.environmentSwitchHandler('production')} className={this.state.hub?.environment === 'production' ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-outline-primary'}>Production</button>
                                </div>
                            </div>
                        </div>

                    
                    </div>
                </SectionHeader>

                {this.state.hubLoading ? <LoadingSpinner /> : 
                    <HubShowSubMenu location={this.props.location.pathname} hubId={this.props.match.params.hubId} />
                }

                <div className="row">
                    <div className="col-6">
                        <section className="HubShowDetailBox">
                            {this.state.hubLoading ? 
                            <>
                                <LoadingSpinner />
                            </> :  
                            <>
                                <h4>Hub ID: {this.props.match.params.hubId}</h4>
                                <p><strong>Key:</strong> {this.state.hub?.key}</p>
                                <p><strong>SKU:</strong> {this.state.hub?.sku} ({HubHelper.hubType(this.state.hub?.sku)})</p>
                                <p><strong>Last Message:</strong> {DateHelper.formatDate(this.state.hub?.lastSeenDate)}</p>
                                {this.state.hub?.environment ? <p><strong>Current Environment:</strong> {this.state.hub?.environment}</p> : ''}
                                
                            </>}
                        </section>
                    </div>

                    <div className="col-3">
                        <section className="HubShowMsgCountBox">
                            {this.state.hubLoading ? 
                            <>
                                <LoadingSpinner />
                            </> :  
                            <>
                                <p><small>Messages published</small></p>
                                <h4>
                                    {
                                        this.state.hub?.messagesPublished ?
                                        <><FontAwesomeIcon icon="paper-plane" /> {this.state.hub?.messagesPublished.messages}</> : ''
                                    }
                                </h4>
                            </>}
                        </section>
                    </div>

                    <div className="col-3">
                        <section className={HubHelper.status(this.state.hub?.heartBeat) ? "HubShowOnlineOfflineBox online" : "HubShowOnlineOfflineBox offline"}>
                            {this.state.hubLoading ? 
                            <>
                                <LoadingSpinner />
                            </> :  
                            <>
                                <p><small>{DateHelper.formatDate(this.state.hub?.heartBeat || this.state.hub?.lastSeenDate)}</small></p>
                                <h4>
                                    { HubHelper.status(this.state.hub?.heartBeat || this.state.hub?.lastSeenDate) ?
                                        <><FontAwesomeIcon icon="signal" /> Online</> :
                                        <><FontAwesomeIcon icon="signal-alt-slash" /> Offline</>
                                    }
                                </h4>
                            </>}
                        </section>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <section className="HubShowChart">
                            <div className="row">
                                <div className="col-12">
                                    <h4>Activity {this.state.chartLoading ? <LoadingSpinner /> : null}</h4>
                                </div>
                                <div className="col-12 chartArea">
                                    <TelemetryChart loading={this.chartLoadingHandler} polling={true} pollingTimer={60000} searchQueryParams={"?fields.packet.hubId=" + this.props.match.params.hubId} offsetLimit="50" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <section className="deviceList">
                            <h4>Online Devices</h4>
                            {this.state.hubLoading ? 
                            <>
                                <LoadingSpinner />
                            </> :  
                            <>
                                <DeviceList count={false} listType="online" devices={this.state.devices} />
                            </>}
                        </section>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <section className="deviceList">
                            <h4>Offline Devices <small>(Last 5 days)</small></h4>
                            {this.state.hubLoading ? 
                            <>
                                <LoadingSpinner />
                            </> :  
                            <>
                                <DeviceList count={false} listType="offline" devices={this.state.devices} />
                            </>}
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        hubs: state.hub.hubs,
        selectedHub: state.hub.selectedHub
    }
}

const mapDispactToProps = dispatch => {
    return {
        setSelectedHub: (hub) => dispatch({ type: Actions.SET_SELECTED_HUB, payload: hub })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(HubShow)
