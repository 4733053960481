/*
    [DeviceHelper]
*/
import * as moment from 'moment'
import { Device } from '../store/Api/Echolo/interfaces/device.interface'
export const status = (lastSeenDate, minsInPast = 20) => {
	let deviceDate = new Date(Date.parse(lastSeenDate))
	let dateInPast = new Date()
	dateInPast.setMinutes(dateInPast.getMinutes() - minsInPast)

	if (dateInPast < deviceDate && lastSeenDate) {
		// Online
		return true
	} else {
		// Offline
		return false
	}
}

export const filterOldDevices = (devices: Device[], hours = 120): Promise<Device[]> => {
	return new Promise((resolve, reject) => {
		let cleanedDevices: Device[] = []
		let count = 0
		let fullCount = 0
		try {
			fullCount = devices.length
		} catch (error) {
			fullCount = 0
		}
		if (fullCount > 0) {
			devices.forEach(d => {
				let deviceDate = moment.default(Date.parse(d.lastSeenDate))
				let dateInPast = moment.default().subtract(hours, 'hours')
                

				if (dateInPast < deviceDate && d.lastSeenDate) {
					// Online
					cleanedDevices.push(d)
				}
        
				count++
				if (count === fullCount) {
					resolve(cleanedDevices)
				}
			})
		} else {
			resolve([])
		}
	})
}
