import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withCookies, Cookies } from 'react-cookie'
import * as Actions from './store/actions'
import AppRoute from './Core/AppRoute'
import { Switch, Redirect } from 'react-router-dom'
import _ from 'lodash'

import ErrorPage from './pages/ErrorPage'
import Login from './pages/Login'
import Register from './pages/Register'
import ConfirmRegister from './pages/Register/ConfirmRegister'
import ResetPassword from './pages/Register/ResetPassword'
import ForgotPassword from './pages/Register/ForgotPassword'

import Dashboard from './pages/Dashboard'

import MainLayout from './Layouts/MainLayout'
import BasicLayout from './Layouts/BasicLayout'

import User from './pages/User/User'

import Hubs from './pages/Hubs/Hubs'
import HubShow from './pages/Hubs/HubShow'
import HubTelemetry from './pages/Hubs/HubTelemetry'
import HubDouble from './pages/Hubs/HubDouble'

import HubTemplates from './pages/HubTemplates/HubTemplates'
import HubTemplateNew from './pages/HubTemplates/HubTemplateNew'
import HubTemplateEdit from './pages/HubTemplates/HubTemplateEdit'

import Devices from './pages/Devices/Devices'
import DeviceShow from './pages/Devices/DeviceShow'

import Gateways from './pages/Gateways/Gateways'
import GatewaysShow from './pages/Gateways/GatewaysShow'

import Brokers from './pages/Brokers/Brokers'

import Telemetry from './pages/Telemetry/Telemetry'

import Explorer from './pages/Explorer/Explorer'

import ServiceStatus from './pages/ServiceStatus/ServiceStatus'

import ApplicationInfo from './pages/Applications/ApplicationInfo'

// TEMP PAGES
import FeverInspect from './pages/FeverInspect/Feverinspect'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

import Amplify from 'aws-amplify'
import awsExports from './aws-exports'

import './App.scss'
import 'bootstrap/dist/js/bootstrap'

Amplify.configure(awsExports)

library.add(fad, fas)

class App extends Component {

    state = {
        loggedIn: false
    }

    isLoggedIn = () => {
        if (!_.isEmpty(this.props.currentUser)) {
            this.setState({
                loggedIn: true
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.currentUser !== this.props.currentUser) {
            this.isLoggedIn()
        }
    }

    componentDidMount() {
        // Check for current user
        // if (this.props.cookies.get('currentUser')) {
        //     this.props.setCurrentUser(this.props.cookies.get('currentUser'))
        // }

        // if (this.props.currentUser.id) {
        //     // User is logged in
        //     this.props.setCurrentApp(JSON.parse(localStorage.getItem('currentApp')))
        // }
        this.isLoggedIn()
        if (_.isEmpty(this.props.currentUser.xapi_key) || _.isUndefined(this.props.currentUser.xapi_key)) {
            this.props.setCurrentUser({})
            this.props.setCurrentApp({})
            this.props.setCurrentUserApplications({})
            this.props.setApplicationNumbers({})
            this.props.cookies.set('currentUser', null, { path: '/' })
            localStorage.removeItem('currentUser')
            localStorage.removeItem('currentapp')
            this.setState({
                loggedIn: false
            })
        }
    }

    render() {
        return (
            <Switch>
                <AppRoute component={Login} path="/" exact layout={BasicLayout}>
                    { this.state.loggedIn ? <Redirect to="/dashboard" /> : <AppRoute component={Login} path="/" layout={BasicLayout}></AppRoute> }
                </AppRoute>

                <AppRoute component={ForgotPassword} path="/forgotpassword" exact layout={BasicLayout}></AppRoute>
                <AppRoute component={Register} path="/register" exact layout={BasicLayout}></AppRoute>
                <AppRoute component={ConfirmRegister} path="/register/confirm" exact layout={BasicLayout}></AppRoute>
                <AppRoute component={ResetPassword} path="/register/resetpassword" exact layout={BasicLayout}></AppRoute>

                {this.state.loggedIn ?
                <>
                    <Switch>
                        <AppRoute component={Dashboard} path="/dashboard" exact layout={MainLayout}></AppRoute>
                        <AppRoute component={Hubs} path="/hubs" exact layout={MainLayout}></AppRoute>

                        <AppRoute component={HubShow} path="/hubs/:hubId" exact layout={MainLayout}></AppRoute>
                        <AppRoute component={HubDouble} path="/hubs/:hubId/double" exact layout={MainLayout}></AppRoute>
                        <AppRoute component={HubTelemetry} path="/hubs/:hubId/telemetry" exact layout={MainLayout}></AppRoute>

                        <AppRoute component={HubTemplates} path="/hub/templates" exact layout={MainLayout}></AppRoute>
                        <AppRoute component={HubTemplateNew} path="/hub/templates/new" exact layout={MainLayout}></AppRoute>
                        <AppRoute component={HubTemplateEdit} path="/hub/templates/:templateId" exact layout={MainLayout}></AppRoute>

                        <AppRoute component={Devices} path="/devices" exact layout={MainLayout}></AppRoute>
                        <AppRoute component={DeviceShow} path="/device/:deviceId" exact layout={MainLayout}></AppRoute>

                        <AppRoute component={Gateways} path="/gateways" exact layout={MainLayout}></AppRoute>
                        <AppRoute component={GatewaysShow} path="/gateways/:gatewayId" exact layout={MainLayout}></AppRoute>

                        <AppRoute component={Brokers} path="/brokers" exact layout={MainLayout}></AppRoute>
                        <AppRoute component={Telemetry} path="/telemetry" exact layout={MainLayout}></AppRoute>
                        <AppRoute component={ServiceStatus} path="/servicestatus" exact layout={MainLayout}></AppRoute>

                        <AppRoute component={User} path="/user" exact layout={MainLayout}></AppRoute>
                        <AppRoute component={Explorer} path="/explorer" exact layout={MainLayout}></AppRoute>

                        <AppRoute component={FeverInspect} path="/feverinspect" exact layout={MainLayout}></AppRoute>

                        <AppRoute component={ApplicationInfo} path="/application-info" exact layout={MainLayout}></AppRoute>

                        <AppRoute component={ErrorPage} layout={BasicLayout}></AppRoute>
                    </Switch>
                </> :
                    <AppRoute component={Login} path="/" layout={BasicLayout}></AppRoute>
                }
                <AppRoute component={Login} path="/signin" layout={BasicLayout}></AppRoute>
                <AppRoute component={ErrorPage} layout={BasicLayout}></AppRoute>

            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentApp: (apps) => dispatch({ type: Actions.SET_CURRENT_APPLICATION, payload: apps }),
        setCurrentUser: (user) => dispatch({ type: Actions.SET_CURRENT_USER, payload: user }),
        setCurrentUserApplications: (apps) => dispatch({ type: Actions.SET_CURRENT_USER_APPLICATIONS, payload: apps }),
        setApplicationNumbers: (data) => dispatch({ type: Actions.SET_APPLICATION_NUMBERS, payload: data }),
    }
}

export default withCookies(connect(mapStateToProps, mapDispactToProps)(App))
