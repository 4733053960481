import React, { Component } from 'react'
import { connect } from 'react-redux'

import LoadingSpinner from '../../Components/Shared/LoadingSpinner'

import * as Gateway from '../../store/Api/Echolo/Gateway'
import SectionHeader from '../../Components/Shared/SectionHeader'

class GatewaysShow extends Component {

    state = {
        currentApp: this.props.currentApp,
        currentGateway: {},
        gatewayLoading: true
    }

    componentDidMount() {
        Gateway.get(this.state.currentApp, this.props.match.params.gatewayId, this.props.currentUser).then(data => {
            console.log("[Gateway SHOW]: ", data)
            this.setState({
                currentGateway: data,
                gatewayLoading: false
            })
        })
    }

    render() {
        let breadcrumb = ['gateways']
        return (
            <div>
                <SectionHeader icon="broadcast-tower" title={this.props.match.params.gatewayId} breadcrumb={breadcrumb}></SectionHeader>
                <section>
                    <h4>Gateway {this.props.match.params.gatewayId}</h4>
                    {this.state.gatewayLoading ? <LoadingSpinner /> : null}
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp
    }
}

export default connect(mapStateToProps)(GatewaysShow)
