import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as Actions from '../../store/actions'

import SectionHeader from '../../Components/Shared/SectionHeader'
import Map from '../../Components/Explorer/Map'

class Explorer extends Component {
	render() {
		let breadcrumb = [{name: 'Explorer', path: 'explorer'}]
		return (
			<div>
				<SectionHeader breadcrumb={breadcrumb} icon="satellite" title="Explorer" />
				<div className="row">
					<div className="col-12">
						<section>
							<Map />
						</section>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		currentUser: state.user.currentUser,
		currentApp: state.app.currentApp,
		hubs: state.hub.hubs
	}
}

const mapDispactToProps = dispatch => {
	return {
		setCurrentApp: (apps) => dispatch({ type: Actions.SET_CURRENT_APPLICATION, payload: apps }),
		setHubList: (hubs) => dispatch({ type: Actions.SET_HUB_LIST, payload: hubs })
	}
}

export default connect(mapStateToProps, mapDispactToProps)(Explorer)
