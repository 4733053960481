import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'
import { JsonEditor as Editor } from 'jsoneditor-react'
import { unflatten } from 'flat'
import _ from 'lodash'

import { update } from '../../store/Api/Echolo/HubDouble'
import * as Template from '../../store/Api/Echolo/Template'

import HubShowSubMenu from '../../Layouts/menus/HubShowSubMenu'
import SectionHeader from '../../Components/Shared/SectionHeader'
import HubG1Control from '../../Components/Hubs/HubG1Control'
import HubTemplateList from '../../Components/HubTemplates/HubTemplateList'
import LoadingSpinner from '../../Components/Shared/LoadingSpinner'

import 'jsoneditor-react/es/editor.min.css'

import { Hub, HubTemplate } from '../../store/Api/Echolo/interfaces/hub.interface'
import { Application } from '../../Interfaces/application.interface'
import { User } from '../../Interfaces/user.interface'

type HubeDoubleState = {
    updatedHubDouble?: { [key: string]: any },
    doubleSaveButtonClass: string,
    templates: HubTemplate[],
    showalert: any,
    showTemplateAlert: any
}

class HubDouble extends React.Component<{
    currentApp: Application,
    currentUser: User,
    selectedHub: Hub,
    setSelectedHub: any,
    location: any,
    match: any
}, HubeDoubleState> {
    state: HubeDoubleState = {
        doubleSaveButtonClass: 'btn-primary',
        templates: [],
        showalert: null,
        showTemplateAlert: null
    }

    editor1: any

    set1EditorRef = instance => this.editor1 = instance;

    handleDoubleChange = (changes) => {
        this.setState({
            updatedHubDouble: changes,
            doubleSaveButtonClass: 'btn-warning'
        })
    }

    saveHubDouble = () => {
        // send to API
        update(this.props.currentApp, this.props.selectedHub.hubId, this.state.updatedHubDouble, this.props.currentUser).then(double => {
            this.setState({
                showalert: (<div className="alert alert-success" role="alert">
                                The Hub Double has been updated!
                            </div>),
                doubleSaveButtonClass: 'btn-primary'
            })

            // Update the Selected Hub
            let selectedHub = this.props.selectedHub
            selectedHub.double = double
            this.props.setSelectedHub(selectedHub)

        }).catch(e => {
            this.setState({
                showalert: (<div className="alert alert-danger" role="alert">
                                I am sorry! There was an issue. {e.message}
                            </div>)
            })
        })
    }

    componentDidMount = () => {
        let { double } = this.props.selectedHub
        if (_.isEmpty(double)) {
            double = '{}'
        }
        this.setState({
            updatedHubDouble: unflatten(JSON.parse(double))
        })

        Template.all(this.props.currentApp, this.props.currentUser).then(templates => {
            this.setState({
                templates: templates
            })
        }).catch(error => {
            this.setState({
                showTemplateAlert: (<div className="alert alert-danger" role="alert">
                            There was an issue fetching your Hub Templates. {error.message}
                        </div>)
            })
        })
    }

    applyTemplateHandler = (template) => {
        let currentDouble = this.state.updatedHubDouble

        this.setState({
            showTemplateAlert: (<div className="alert alert-success" role="alert">
                                    Your Hub Template was applied to the Hub Double!
                                </div>),
            updatedHubDouble: { ...JSON.parse(template.template), ...currentDouble },
            doubleSaveButtonClass: 'btn-warning'
        })

        this.editor1.jsonEditor.set({ ...JSON.parse(template.template), ...currentDouble })

    }

    render() {
        let breadcrumb = [{name: 'Hubs', path: 'hubs'}, {name: this.props.match.params.hubId, path: 'hubs/' + this.props.match.params.hubId}, {name: "Hub Double", path: 'hubs/' + this.props.match.params.hubId + '/double'}]
        let { sku } = this.props.selectedHub

        return (
            <div>
                <SectionHeader breadcrumb={breadcrumb} icon="router" title="Hub Double">
                    <div className="row">
                        <div className="col-4">
                            
                        </div>
                        <div className="col-8 text-right">
                            {sku ?
                            <>
                                {this.props.selectedHub.sku.includes("EL-G1") ? <HubG1Control hub={this.props.selectedHub} /> : null }
                            </>
                            : null}
                        </div>
                    </div>
                </SectionHeader>

                <HubShowSubMenu location={this.props.location.pathname} hubId={this.props.selectedHub.hubId} />

                <div className="row justify-content-center">
                    <div className="col-7">
                        <section>
                            {this.state.showalert}
                            <div className="HubDoubleEditor">
                                {this.state.updatedHubDouble != null ? <Editor mode="code" ref={this.set1EditorRef} value={this.state.updatedHubDouble} onChange={this.handleDoubleChange} /> : <LoadingSpinner />}
                            </div>
                            <div className="text-right">
                                <button className={"btn btn-sm HubDoubleEditorButton " + this.state.doubleSaveButtonClass} onClick={this.saveHubDouble}>Save</button>
                            </div>
                        </section>
                    </div>

                    <div className="col-5">
                        <section className="ApplyHubTemplate">
                            <h4>Avalable Hub Templates</h4>
                            {this.state.showTemplateAlert}
                            <HubTemplateList templates={this.state.templates} canApply={true} apply={this.applyTemplateHandler} />
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        selectedHub: state.hub.selectedHub
    }
}

const mapDispactToProps = dispatch => {
    return {
        setSelectedHub: (hub) => dispatch({ type: Actions.SET_SELECTED_HUB, payload: hub })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(HubDouble)
