import React from 'react'

const BrokersListItem = (props) => {
	return (
		<div>
			<h4>{props.broker.brokerId}</h4>
			<p>{props.broker.localIp}</p>
		</div>
	)
}

export default BrokersListItem
