import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Actions from '../store/actions'

import * as AppApi from '../store/Api/Echolo/Application'

import ServiceStatusIncidents from '../Helpers/ServiceStatus/ServiceStatusIncidents'

import TelemetryChart from '../Components/Telemetry/TelemetryChart'
import ApplicationCreate from '../Components/Applications/ApplicationCreate'

import { Application, ApplicationNumbers } from '../Interfaces/application.interface'
import { User } from '../Interfaces/user.interface'

class Dashboard extends React.Component<{currentApp: Application, currentUser: User, accountNumbers: ApplicationNumbers, setApplicationNumbers: any}, {}> {
    state = {
        isIncident: false,
        devicesOnline: 0,
        devicesOffline: 0,
        hubsOnline: 0,
        hubsOffline: 0,
        hubs: 0,
        chartLoading: false,
        showSecret: false
    }

    timer: ReturnType<typeof setInterval> | null = null

    serviceStatusHandler = (status) => {
        this.setState({
            isIncident: status
        })
    }

    buildNumbers = (data: ApplicationNumbers) => {
        this.setState({
            devicesOnline: data.deviceNumbers.onlineDevices.length,
            devicesOffline: data.deviceNumbers.offlineDevices.length,
            hubsOnline: data.hubNumbers.onlineHubs.length,
            hubsOffline: data.hubNumbers.offlineHubs.length,
            hubs: data.hubNumbers.onlineHubs
        })
    }

    getAppNumbers = () => {
        AppApi.numbers(this.props.currentApp, this.props.currentUser).then(data => {
            this.buildNumbers(data)
            this.props.setApplicationNumbers(data)
        })
    }

    componentDidMount = () => {
        let updateNumbersMins = 10
        if (_.isEmpty(this.props.accountNumbers) && !_.isEmpty(this.props.currentApp)) {
            this.getAppNumbers()
        } else {
            if (!_.isEmpty(this.props.currentApp) && !_.isEmpty(this.props.accountNumbers)) {
                this.buildNumbers(this.props.accountNumbers)
                if (this.props.accountNumbers.hubNumbers.onlineHubs.length === 0) {
                    updateNumbersMins = 1
                }
            }
        }
        if (!_.isEmpty(this.props.currentApp)) {
            this.getAppNumbers()
            this.timer = setInterval(()=> this.getAppNumbers(), 60000*updateNumbersMins)
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.timer as ReturnType<typeof setInterval>)
        this.timer = null
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.currentApp !== this.props.currentApp && _.isEmpty(this.props.currentApp)) {
            this.setState({
                devicesOnline: 0,
                devicesOffline: 0,
                hubsOnline: 0,
                hubsOffline: 0
            })

        }

        if (prevProps.currentApp !== this.props.currentApp && !_.isEmpty(this.props.currentApp)) {
            this.getAppNumbers()
        }
    }

    chartLoadingHandler = (status) => {
        this.setState({
            chartLoading: status
        })
    }


    render() {
        return (
            <div>
                <div className="row justify-content-center">
                    {!_.isEmpty(this.props.currentApp) ?
                        <>
                            <div className={this.state.isIncident ? "col-2" : "col-3"}>
                                <section className="HubCountBox DashboardStatBox">
                                    <h4>Hubs Status</h4>
                                    <p><strong>Online:</strong> {this.state.hubsOnline}</p>
                                    <p><strong>Offline:</strong> {this.state.hubsOffline}</p>
                                </section>
                            </div>
                            <div className="col-3">
                                <section className="onlineBox DashboardStatBox">
                                    <Link to="/devices">
                                        <h4>Online Devices</h4>
                                        <p>{this.state.devicesOnline}</p>
                                    </Link>
                                </section>
                            </div>

                            <div className="col-3">
                                <section className="offlineBox DashboardStatBox">
                                    <Link to="/devices?offline=true">
                                        <h4>Offline Devices</h4>
                                        <p>{this.state.devicesOffline}</p>
                                    </Link>
                                </section>
                            </div>

                            <ServiceStatusIncidents serviceStatus={this.serviceStatusHandler} />
                        </>
                     : null}
                </div>

                <div className="row justify-content-center">
                    {_.isEmpty(this.props.currentApp) ?
                        <ApplicationCreate user={this.props.currentUser} />
                        : null}
                    <div className="col-6">
                    {_.isEmpty(this.props.currentApp) ?
                        <section className="iot-bg">
                            <div className="alert alert-success">
                                <h4 className="alert-heading"><FontAwesomeIcon icon="window-alt" /> Welcome to the Echolo IoT Dashboard</h4>
                                <p>Welcome to the <strong>Echolo IoT Dashboard</strong>. On this page, you can access key details about your selected application, providing a comprehensive overview
                                    of your deployment, along with essential metrics such as message usage and more.</p>

                                <hr />

                                <h4><strong>Get Started</strong></h4>
                                <p className="mb-0">If you already have your application ID (<em>appId</em>), please add your application. If you already have added your application, you may use the dropdown menu at the top right-hand side of the screen to select your app!</p>

                                <hr />

                                <p><strong>Support:</strong> <a target={"_blank"} href={"http://support.echolo.io"}>Find hardware configurations, API Setup, and more.</a></p>
                            </div>
                        </section>
                    :
                        <section className="iot-bg">
                            <div className="alert alert-info">
                                <h4 className="alert-heading"><FontAwesomeIcon icon="code" /> {this.props.currentApp.name}</h4>
                                <p><strong>Hello! Welcome to the Echolo Dashboard.</strong> Your current selected application is "{this.props.currentApp.name}" to change this, use the select box in the top right corner of the screen. Below is basic information about your application. Some of this data is private and should not be shared, such as your Application Secret.</p>
                                <hr />

                                <h5>Application Information</h5>
                                <ul>
                                    <li><strong>Application ID:</strong> <pre>{this.props.currentApp.appId}</pre></li>
                                    <li><strong>Application Secret:</strong> <pre>{this.state.showSecret ? <span>{this.props.currentApp.secret} <span onClick={() => this.setState({ showSecret: !this.state.showSecret })}>(hide)</span></span> : <span onClick={() => this.setState({ showSecret: !this.state.showSecret })}>show</span>}</pre></li>
                                </ul>
                            </div>
                        </section>
                    }

                    </div>
                    {!_.isEmpty(this.props.currentApp) ?
                        <div className="col-6">
                            <section>
                                {this.state.chartLoading ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  : null}
                                <TelemetryChart loading={this.chartLoadingHandler} searchQueryParams={''} currentUser={this.props.currentUser} currentApp={this.props.currentApp} offsetLimit="100" polling={true} pollingTimer={121000} />
                            </section>
                        </div> : null }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        accountNumbers: state.app.applicationNumbers
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentUser: (user) => dispatch({ type: Actions.SET_CURRENT_USER, payload: user }),
        setApplicationNumbers: (data) => dispatch({ type: Actions.SET_APPLICATION_NUMBERS, payload: data })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(Dashboard)
