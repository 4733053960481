/* 
    /templates
    /templates/ID
*/
import axios from 'axios'

import { HubTemplate } from './interfaces/hub.interface'

let headers = (user) => {
	return {
		headers: {
			'X-User-Token': user.authentication_token,
			'X-User-Email': user.email
		}
	}
}

let tokenHeaders = (app, user) => {
	return {
		headers: {
			'x-api-key': user.xapi_key,
			'appId': app.appId,
			'token': app.token,
		}
	}
}

export const all = (app, user): Promise<HubTemplate[]> => {
	return new Promise((resolve, reject) => {
		axios.get('/hub-templates/', tokenHeaders(app, user)).then(profile => {
			resolve(profile.data)
		}).catch(error => {
			reject(error)
		})
	})
}

export const get = (app, user, templateId) => {
	return new Promise((resolve, reject) => {
		axios.get('/hub-templates/' + templateId, tokenHeaders(app, user)).then(profile => {
			resolve(profile.data)
		}).catch(error => {
			reject(error)
		})
	})
}

export const create = (app, user, data) => {
	return new Promise((resolve, reject) => {
		axios.post('/hub-templates/', { data: { name: data.name, template: data.template } }, tokenHeaders(app, user)).then(profile => {
			resolve(profile.data)
		}).catch(error => {
			reject(error)
		})
	})
}

export const update = (app, user, templateId, data) => {
	return new Promise((resolve, reject) => {
		axios.put('/hub-templates/' + templateId, { data: { name: data.name, template: data.template } }, tokenHeaders(app, user)).then(profile => {
			resolve(profile.data)
		}).catch(error => {
			reject(error)
		})
	})
}

export const destroy = (app, user, templateId) => {
	return new Promise((resolve, reject) => {
		axios.delete('/hub-templates/' + templateId, tokenHeaders(app, user)).then(profile => {
			resolve(profile.data)
		}).catch(error => {
			reject(error)
		})
	})
}
