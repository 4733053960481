import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// @HubHelper.statusIcon
// Feed it hub.lastSeenDate
// Returns: Online or Offline Icon
export const statusIcon = (lastSeenDate, type = 'on-off') => {
	let hubDate = new Date(Date.parse(lastSeenDate))
	let dateInPast = new Date()
	dateInPast.setMinutes(dateInPast.getMinutes() - 20)

	if (type === 'on-off') {
		if (dateInPast < hubDate && lastSeenDate) {
			// Online
			return <FontAwesomeIcon className="online-icon" icon="signal-alt" />
		} else {
			// Offline
			return <FontAwesomeIcon className="offline-icon" icon="signal-alt-slash" />
		}
	} else if (type === 'comms') {
		if (dateInPast < hubDate && lastSeenDate) {
			// Online
			return <FontAwesomeIcon className="online-icon" icon="exchange" />
		} else {
			// Offline
			return <FontAwesomeIcon className="offline-icon" icon="exchange-alt" />
		}
	}

}

export const status = (lastSeenDate) => {
	let hubDate = new Date(Date.parse(lastSeenDate))
	let dateInPast = new Date()
	dateInPast.setMinutes(dateInPast.getMinutes() - 20)

	if (dateInPast < hubDate && lastSeenDate) {
		// Online
		return true
	} else {
		// Offline
		return false
	}
}

export const hubType = (sku) => {
	if (!sku) return 'Unknown'
	if (sku.includes('EL-ILH') || sku.includes('EL-OLH')) {
		return 'LoRa'
	} else {
		return 'BLE'
	}
}
