import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as DateHelper from '../DateHelper'

const ServiceStatusIncidentsItem = ({ incident }) => {
	/*
        incident.impact = minor, major, critical, none
        started_at = Date
        status = String ("investigating", "identified", "monitoring", "resolved")
        incident_updates = Array
        components = Array
    */
	let incident_updates = incident.incident_updates.map((update, key) => {
		return (
			<li key={key}>
				<strong>{DateHelper.formatDate(update.created_at)}</strong>
				<p>{update.body}</p>
			</li>
		)
	})

	let components = incident.components.map((component, key) => {
		// status: "operational", "under_maintenance", "degraded_performance", "major_outage"
		return (
			<li className={component.status} key={key}>
				{component.status === 'operational' ? <FontAwesomeIcon icon="check" /> : null }
				{component.status === 'under_maintenance' ? <FontAwesomeIcon icon="wrench" /> : null }
				{component.status === 'degraded_performance' ? <FontAwesomeIcon icon="exclamation" /> : null }
				{component.status === 'partial_outage' ? <FontAwesomeIcon icon="exclamation" /> : null }
				{component.status === 'major_outage' ? <FontAwesomeIcon icon="times" /> : null}
				{component.name}
			</li>
		)
	})

	let boxStyle = 'alert-light'
	switch(incident.status) {
	case 'investigating':
		boxStyle = 'alert-warning'
		break
	case 'identified':
		boxStyle = 'alert-warning'
		break
	case 'monitoring':
		boxStyle = 'alert-light'
		break
	default:
		boxStyle = 'alert-light'
	}

	return (
		<div className={'alert ServiceStatusIncidentsItem ' + boxStyle} role="alert">
			<h5>{incident.status === 'investigating' ? <span className="badge badge-warning">{incident.status}</span> : null}
				{incident.status === 'identified' ? <span className="badge badge-secondary">{incident.status}</span> : null}
				{incident.status === 'monitoring' ? <span className="badge badge-light">{incident.status}</span> : null}
				{incident.status === 'resolved' ? <span className="badge badge-success">{incident.status}</span> : null}
				<span> {incident.name} </span>
			</h5>
			<hr />
			<p className="updatesTitle mb-0"><strong>Updates:</strong></p>
			<ul className="updates">
				{incident_updates}
			</ul>

			<p className="mb-0"><strong>Affected Services:</strong></p>
			<ul className="servicesList">
				{components}
			</ul>
		</div>
	)
}

export default ServiceStatusIncidentsItem
