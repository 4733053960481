import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class LeftMenu extends Component {
	render() {
		return (
			<div>

				<div className="LeftMenu-Link">
					<Link className={this.props.locationPath === '/dashboard' ? 'rounded navLink active' : 'rounded navLink'} to="/dashboard"><FontAwesomeIcon icon="tachometer-alt" /> Dashboard</Link>
				</div>

				<div className="LeftMenu-Link">
					<Link className={this.props.locationPath === '/servicestatus' ? 'rounded navLink active' : 'rounded navLink'} to="/servicestatus"><FontAwesomeIcon icon="shield-check" /> Service Status</Link>
				</div>

				{!_.isEmpty(this.props.currentApp) ? 
					<>
						<div className="LeftMenu-HasSubLinks">
							<div className="LeftMenu-Link">
								<Link className={this.props.locationPath === '/hubs' || this.props.locationPath.includes('/hubs') ? 'rounded navLink active' : 'rounded navLink'} to="/hubs">
									<FontAwesomeIcon icon="router" /> Hubs
								</Link>
							</div>

							{!_.isEmpty(this.props.selectedHub) && false ?
								<>
									<p className="SelectedText">Selected:</p>
									<div className="LeftMenu-SubLink">
										<Link className="rounded navLink subLink" to={'/hubs/' + this.props.selectedHub.hubId}>
											<FontAwesomeIcon icon="router" /> {this.props.selectedHub.hubId}
											<span className="float-right"><FontAwesomeIcon icon="caret-left" /></span>
										</Link>
									</div>
								</>
								: null}

							<div className="LeftMenu-SubLink">
								<Link className={this.props.locationPath === '/hub/templates' || this.props.locationPath.includes('/hub/templates') ? 'rounded navLink active' : 'rounded navLink'} to="/hub/templates">
									<FontAwesomeIcon icon="brackets" /> Hub Templates
								</Link>
							</div>
						</div>

						<div className="LeftMenu-Link">
							<Link className={this.props.locationPath === '/devices' || this.props.locationPath.includes('/devices') ? 'rounded navLink active' : 'rounded navLink'} to="/devices">
								<FontAwesomeIcon icon="microchip" /> Devices
							</Link>
						</div>

						{!_.isEmpty(this.props.selectedDevice) ?
							<>
								<p className="SelectedText">Selected:</p>
								<div className="LeftMenu-SubLink">
									<Link className="rounded navLink subLink" to={'/device/' + this.props.selectedDevice._id}>
										<FontAwesomeIcon icon="microchip" /> {this.props.selectedDevice.nodeId}
										<span className="float-right"><FontAwesomeIcon icon="caret-left" /></span>
									</Link>
								</div>
							</>
							: null}

						<div className="LeftMenu-Link">
							<Link className={this.props.locationPath === '/application-info' ? 'rounded navLink active' : 'rounded navLink'} to="/application-info"><FontAwesomeIcon icon="code" /> API & Usage</Link>
						</div>
					</> : null
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		currentUser: state.user.currentUser,
		currentApp: state.app.currentApp,
		selectedHub: state.hub.selectedHub,
		selectedDevice: state.device.selectedDevice
	}
}

export default connect(mapStateToProps)(LeftMenu)