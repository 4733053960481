/*
    [TelemetryDataHelper]
*/
import moment from 'moment'
import _ from 'lodash'

import * as DateHelper from './DateHelper'


export const formatForChart = (groupedData) => {
	let chartObj = {}
	// Chart needs:
	/*
        [
            {
                "id": "Serie 1",
                "data": [
                    "x": 123,
                    "y": 123
                ]
            }
        ]
    */

	// Getting in 'groupedData'
	/*
        {
            "Date": [...dates],
            ...
        }
    */

	let dataPrepArray = Object.keys(groupedData).map(i => groupedData[i])
	let groupedDataKeysArray = Object.keys(groupedData)

	chartObj.categories = [] // AKA x
	chartObj.seriesData = [] // AKA y

	dataPrepArray.forEach((elm, index) => {
		chartObj.seriesData.push(elm.length)
		chartObj.categories.push(DateHelper.formatTime(groupedDataKeysArray[index]))
		//chartObj.data.push({ x: DateHelper.formatTime(groupedDataKeysArray[index]), y: elm.length })
	})
	chartObj.seriesData = chartObj.seriesData.reverse()
	chartObj.categories = chartObj.categories.reverse()
    

	return chartObj

}

export const prepChartData = (rawSearch) => {
	let occurences = []
	rawSearch.forEach(search => {
		occurences.push(search['@timestamp'])
	})
	let groups = _.groupBy(occurences, function (date) {
		//return moment(date).startOf('minute').format()
		return moment(date).startOf('second').format()
	})
    
	return formatForChart(groups)
}