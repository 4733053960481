import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'

import * as Broker from '../../store/Api/Echolo/Broker'

import BrokersList from '../../Components/Brokers/BrokersList'
import LoadingSpinner from '../../Components/Shared/LoadingSpinner'

import SectionHeader from '../../Components/Shared/SectionHeader'

class Brokers extends Component {

    state = {
        brokers: null,
        brokersLoading: true
    }

    componentDidMount() {
        if (this.props.currentApp) {
            Broker.all(this.props.currentApp).then(data => {
                console.log("[BROKER SHOW]: ", data)
                this.setState({
                    brokers: data,
                    brokersLoading: false
                })
            })
        }
    }

    render() {
        return (
            <div>
                <SectionHeader icon="database" title="Brokers"></SectionHeader>
                <section>
                    <h4>Brokers</h4>
                    {this.props.currentApp ? 
                        <>
                            {this.state.brokersLoading ? <LoadingSpinner /> :
                                <BrokersList brokers={this.state.brokers} />
                            }
                        </> : <p>No Application is selected.</p>
                    }
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        hubs: state.hub.hubs
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentApp: (apps) => dispatch({ type: Actions.SET_CURRENT_APPLICATION, payload: apps }),
        setHubList: (hubs) => dispatch({ type: Actions.SET_HUB_LIST, payload: hubs })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(Brokers)
