import React, { useState } from 'react'
import { JsonEditor as Editor } from 'jsoneditor-react'
import { unflatten } from 'flat'

import LoadingSpinner from '../Shared/LoadingSpinner'

const HubTemplateEditor = ({ template, change, templateName = '' }) => {
	let templateIncoming = unflatten(JSON.parse(template))

	const [name, setName] = useState(templateName)

	const nameChangeHandler = (e) => {
		setName(e.target.value)
		change(JSON.stringify(templateIncoming), e.target.value)
	}

	const handleTemplateChange = (data) => {
		change(JSON.stringify(data), templateName)
	}

	return (
		<div>
			<div className="form-group">
				<label htmlFor="name">Template Name (<span className="text-danger">*</span>)</label>
				<input value={templateName ? templateName : name} onChange={(e) => nameChangeHandler(e)} type="text" className="form-control" id="name" />
			</div>
			{templateIncoming != null ? <Editor value={templateIncoming} onChange={handleTemplateChange} /> : <LoadingSpinner />}
		</div>
	)
}

export default HubTemplateEditor