import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'

import * as Templates from '../../store/Api/Echolo/Template'

import HubTemplateEditor from '../../Components/HubTemplates/HubTemplateEditor'

import SectionHeader from '../../Components/Shared/SectionHeader'

class HubTemplateNew extends Component {
    state = {
        saveButtonClass: 'btn-muted',
        saveButtonState: true,
        template: null,
        templateName: '',
        showalert: null
    }

    saveHandler = () => {
        if (this.state.templateName === '' || !this.state.templateName) {
            console.log("this.state.templateName: ", this.state.templateName)
            this.setState({
                showalert: (<div className="alert alert-danger" role="alert">
                                Please give your Template a name and then try again.
                            </div>)
            })
        } else {
            // Send to Templates.create()
            // Failed? Show error
            // Saved? Send back to list (Redirect)
            Templates.create(this.props.currentApp, this.props.currentUser, { template: JSON.parse(this.state.template), name: this.state.templateName }).then(template => {
                // Redirect
                console.log("TEMPLATE: ", template)
                this.setState({
                    showalert: (<Redirect to="/hub/templates" />)
                })
            }).catch(error => {
                // Show Error
                this.setState({
                    showalert: (<div className="alert alert-danger" role="alert">
                                    <p>There was an issue saving your new Template, please try again.</p>
                                    <p><small>{error.message}</small></p>
                                </div>)
                })
            })
        }
        
    }

    changeHandler = (template, name) => {
        this.setState({
            saveButtonClass: 'btn-success',
            saveButtonState: false,
            template: template,
            templateName: name
        })
    }

    render() {
        let breadcrumb = [{name: 'Hubs', path: 'hubs'}, {name: 'Hub Templates', path: 'hub/templates'}]
        return (
            <div>
                <SectionHeader breadcrumb={breadcrumb} icon="brackets" title="New Hub Template"></SectionHeader>
                <div className="row">
                    <div className="col-12">
                        <section>
                            {this.state.showalert}
                            <HubTemplateEditor template={JSON.stringify({})} templateName={this.state.templateName} change={this.changeHandler} />
                            <div className="text-right mt-3">
                                {this.state.saveButtonState ? 
                                    <button className={"btn " + this.state.saveButtonClass} disabled><FontAwesomeIcon icon="save" /> Save Template</button>
                                : <button onClick={this.saveHandler} className={"btn " + this.state.saveButtonClass}><FontAwesomeIcon icon="save" /> Save Template</button>}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp
    }
}

export default connect(mapStateToProps)(HubTemplateNew)