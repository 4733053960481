// User
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_SESSION_USERNAME = 'SET_SESSION_USERNAME'

// Application
export const SET_CURRENT_USER_APPLICATIONS = 'SET_CURRENT_USER_APPLICATIONS'
export const SET_CURRENT_APPLICATION = 'SET_CURRENT_APPLICATION'
export const SET_APPLICATION_NUMBERS = 'SET_APPLICATION_NUMBERS'

// Hub
export const SET_HUB_LIST = 'SET_HUB_LIST'
export const SET_SELECTED_HUB = 'SET_SELECTED_HUB'

// Device
export const SET_DEVICE_LIST = 'SET_DEVICE_LIST'
export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE'
