import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { Redirect } from 'react-router-dom'

import ResetPasswordForm from '../../Components/User/ResetPasswordForm'
import * as UrlParamHelper from '../../Helpers/UrlParams'

class ResetPassword extends Component {
    state = {
        showAlert: null,
        userEmail: null,
        redirectShow: null
    }

    componentDidMount = () => {
        let params = UrlParamHelper.getParams(this.props.location.search)
        this.setState({
            userEmail: params[0].user
        })
    }

    passwordResetHandler = (userData) => {
        if (userData.password === userData.confirm_password) {
            const { userEmail } = this.state
            console.log("MATCH: ", userData)
            Auth.forgotPasswordSubmit(userEmail, userData.code, userData.password).then(data => {
                console.log(data)
                // Redirect
                this.setState({
                    redirectShow: <Redirect to="/" />
                })
            }).catch(err => {
                console.log(err)
                this.setState({
                    showAlert: <div className="alert alert-danger" role="alert">
                                    I am sorry! There was an issue, please try again. {err.message}
                                </div>
                })
            })
        } else {
            this.setState({
                showAlert: <div className="alert alert-danger" role="alert">
                                I am sorry! There was an issue, please try again.
                            </div>
            })
        }
        console.log(userData)
    }

    render() {
        return (
            <div className="LoginForm">
                {this.state.showAlert}
                {this.state.redirectShow}
                {this.state.userEmail ? <p>Resetting password for: {this.state.userEmail}</p> : null}
                <ResetPasswordForm resetHandler={this.passwordResetHandler} />
            </div>
        )
    }
}

export default ResetPassword