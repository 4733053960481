import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as DateHelper from '../DateHelper'

const ServiceStatusMaintenanceItem = ({ maintenance }) => {

	let incident_updates = maintenance.incident_updates.map((update, key) => {
		return (
			<div key={key} className="row">
				<div className="col-4">
					<p className={'UpdateStatus text-right ' + update.status}>
						{update.status === 'scheduled' ? 'Scheduled' : null}
						{update.status === 'in_progress' ? 'In Progress' : null}
						{update.status === 'completed' ? 'Completed' : null}
					</p>
				</div>
				<div className="col-8">
					<p>{update.body}</p>
					<p><small>{DateHelper.formatDate(update.updated_at)}</small></p>
				</div>
			</div>
		)
	})

	return (
		<div className={'SSMItem ' + maintenance.status}>
			<div className="row">
				<div className="col-12">
					<h5>{maintenance.status === 'in_progress' ? <FontAwesomeIcon icon="spinner-third" size="lg" spin /> : <FontAwesomeIcon icon="clock" size="lg" />} {maintenance.name} <small>
						{maintenance.status === 'scheduled' ? 'Scheduled' : null}
						{maintenance.status === 'in_progress' ? 'In Progress' : null}
						{maintenance.status === 'completed' ? 'Completed' : null}
					</small></h5>
				</div>
				<div className="col-6 MaintenanceStart">
					<p>Starting At: {DateHelper.formatDate(maintenance.scheduled_for)}</p>
				</div>
				<div className="col-6 MaintenanceEnd">
					<p>Ending At: {DateHelper.formatDate(maintenance.scheduled_until)}</p>
				</div>
			</div>

			{incident_updates}
		</div>
	)
}

export default ServiceStatusMaintenanceItem