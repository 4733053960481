/*
    [TelemetryHistoryHelper]
    Used for Telemetry Search History actions
*/

export const setHistory = (history) => {
	localStorage.setItem('searchHistory', JSON.stringify(history))
	return history
}

// Add History
export const add = (currentHistory, newItem) => {
	// Check if this newItem is already in the currentHistory
	let newHistoryKey = Object.keys(newItem)
	if (!currentHistory.some(history => history[newHistoryKey[0]] === newItem[newHistoryKey[0]])) {
		// This is new history

		// Add the new history to the old
		currentHistory = [...currentHistory, newItem]

		// Set History to localStorage
		localStorage.setItem('searchHistory', JSON.stringify(currentHistory))
	}

	return currentHistory
}

export const remove = (currentHistory, index) => {
	currentHistory.splice(index, 1)
	setHistory(currentHistory)
	return currentHistory
}

// Remove all History
export const removeAll = () => {

	localStorage.setItem('searchHistory', JSON.stringify([]))
	return true
    
}
