import axios from 'axios'
const ServiceApi = axios.create({
	baseURL: 'https://wbzgwj9cpg29.statuspage.io/api/v2/'
})

export const ServiceStatus = () => {
	return new Promise((resolve, reject) => {
		ServiceApi.get('status.json').then(d => {
			resolve(d.data)
		}).catch(e => {
			reject(e)
		})
	})
}

export const ServiceSummary = () => {
	return new Promise((resolve, reject) => {
		ServiceApi.get('summary.json').then(d => {
			resolve(d.data)
		}).catch(e => {
			reject(e)
		})
	})
}