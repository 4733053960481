import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'

import GatewayList from '../../Components/Gateways/GatewaysList'
import LoadingSpinner from '../../Components/Shared/LoadingSpinner'

import * as Gateway from '../../store/Api/Echolo/Gateway'
import SectionHeader from '../../Components/Shared/SectionHeader'

class Gateways extends Component { 
    currentApp = JSON.parse(localStorage.getItem('currentApp'))

    state = {
        gateways: [],
        gatewaysLoading: true
    }

    componentDidMount() {
        if (this.props.currentApp) {
            Gateway.all(this.props.currentApp, this.props.currentUser).then(data => {
                console.log("[Gateway INDEX]: ", data)
                this.setState({
                    gateways: data,
                    gatewaysLoading: false
                })
            })
        }
    }

    render() {
        return (
            <div>
                <SectionHeader icon="broadcast-tower" title="Gateways"></SectionHeader>
                <section>
                    {this.props.currentApp ? 
                        <>
                            {this.state.gatewaysLoading ? <LoadingSpinner /> :
                                <GatewayList gateways={this.state.gateways} />
                            }
                        </> : <p>No Application is selected.</p>
                    }
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        hubs: state.hub.hubs
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentApp: (apps) => dispatch({ type: Actions.SET_CURRENT_APPLICATION, payload: apps }),
        setHubList: (hubs) => dispatch({ type: Actions.SET_HUB_LIST, payload: hubs })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(Gateways)