import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ConfirmRegister = (props) => {
	const { sessionUsername, setSessionUsername } = props

	const confirmCode = {
		code: ''
	}
	const [code, setCode] = useState(confirmCode)
	const [redirectToLogin, setRedirectToLogin] = useState(false)
	const [showalert, setShowalert] = useState(null)
	const { register, handleSubmit, errors } = useForm()

	useEffect(() => {
		let isMounted = true

		if (redirectToLogin && isMounted) {
			setSessionUsername(null)
			console.log("Redirect now...")
		}
		return () => { isMounted = false }
	}, [redirectToLogin])

	const confirmHandler = async (formData) => {
		try {
			await Auth.confirmSignUp(sessionUsername, formData.code)
			setRedirectToLogin(true)
		} catch (error) {
			setCode({code: ''})
			setShowalert(<div className="alert alert-danger" role="alert">
				I am sorry! There was an issue with your Verification Code. {error.message}
			</div>)
		}
	}
	const resendHandler = async () => {

		try {
			await Auth.resendSignUp(sessionUsername)
			console.log('code resent successfully')
			setShowalert(<div className="alert alert-success" role="alert">
                            Your Verification Code has been resent to: {sessionUsername}.
			</div>)
		} catch (err) {
			console.log('error resending code: ', err)
			setShowalert(<div className="alert alert-danger" role="alert">
                            I am sorry! There was an issue in resending your Verification Code. {err.message}
			</div>)
		}
	}

	return (
		<div className="LoginForm">
			{sessionUsername ? 
				<>
					{showalert}
					<h3 className="text-center text-dark">Confirm your account</h3>
					<p className="text-center text-subtext">Check your email, we sent you a verification code.</p>
					<form onSubmit={handleSubmit(confirmHandler)}>
						{errors.code && <p className="text-danger">Verification Code is required.</p>}
						<div className="form-group">
							<label htmlFor="code">Verification Code:</label>
							<input 
								value={code.code}
								name="code"
								onChange={e => setCode({ ...code, code: e.target.value })}
								placeholder="000000"
								ref={register({ required: true })}
								type="text" className="form-control" id="code" />
						</div>
						<div className="row">
							<div className="col-12">
								<button type="submit" className="btn btn-outline-primary btn-lg btn-block">Verify <FontAwesomeIcon icon="chevron-right" /></button>
							</div>
						</div>
					</form>

					<div className="row">
						<div className="col-12">
							<hr />
							<p className="text-size-medium">Did you not get your verification? Make sure to allow emails sent from <strong>support@echolo.io</strong>. If you still can not find it <span onClick={() => resendHandler()} className="RetryVerifyCodeLink">try sending the verification code again</span>.</p>
						</div>
					</div>
				</>
				: <Redirect to="/" />}
		</div>
	)

}

const mapStateToProps = state => {
	return {
		sessionUsername: state.user.sessionUsername
	}
}

const mapDispactToProps = dispatch => {
	return {
		setSessionUsername: (username) => dispatch({ type: Actions.SET_SESSION_USERNAME, payload: username })
	}
}
export default connect(mapStateToProps, mapDispactToProps)(ConfirmRegister)