import { HeadersEcholo } from './headers.echolo'
import axios from "axios";
import { APIError, APIErrorInterface } from "./interfaces/error.interface";
import {Application} from "./interfaces/application.interface";

export class TokenEcholo extends HeadersEcholo{
    getToken = (app: Application): Promise<string> => {
        return new Promise(async (resolve, reject) => {
            try {
                const application: any = (await axios.get('/applications/token', this.basicHeaders(app))).data
                if (application.error) reject(new APIError(application as unknown as APIErrorInterface))
                resolve(application.token)
            } catch (error) {
                reject(error)
            }
        })
    }
}
