import React from 'react'
import * as moment from 'moment'
import { ApiUsageInfo } from '../../Interfaces/application.interface'
export const ApplicationApiUsageTable = (props) => {
    const apiUsageData: ApiUsageInfo = props.apiUsageData
    let usageRow = apiUsageData.usage?.usageData.map((item, key) => {
        return (
            <tr key={key}>
                <td>{moment.default(apiUsageData.startDate).add(key, 'days').format('YYYY-MM-DD')}</td>
                <td>{item[0]}</td>
            </tr>
        )
    })
    return (
        <div>
            <table className={'table table-hover'}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Requests</th>
                    </tr>
                </thead>
                <tbody>
                    {usageRow}
                </tbody>
            </table>
        </div>
    );
}

export default ApplicationApiUsageTable;