import axios from 'axios'

let headers = (user) => {
	return {
		headers: {
			'X-User-Token': user.authentication_token,
			'X-User-Email': user.email
		}
	}
}

export const all = (app, user) => {
	return new Promise((resolve, reject) => {
		axios.get('/applications/' + app.app_identifier + '/gateways', headers(user)).then(res => {
			resolve(res.data)
		}).catch(e => {
			console.error(e.message)
			reject(e)
		})
	})
}

export const get = (app, gatewayId, user) => {
	headers()
	return new Promise((resolve, reject) => {
		axios.get('/applications/' + app.app_identifier + '/gateways/' + gatewayId, headers(user)).then(res => {
			resolve(res.data)
		}).catch(e => {
			console.error(e.message)
			reject(e)
		})
	})
}
