import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'


import * as Search from '../../store/Api/Echolo/Search'

import * as TelemetryDataHelper from '../../Helpers/TelemetryDataHelper'
import * as UrlParamHelper from '../../Helpers/UrlParams'

/*
    PROPS NEEDED:
    searchQueryParams => location.search
    currentApp => from LocalStore or State
    currentUser
    offsetLimit => Number
    polling => boolean
    pollingTimer => Number
*/

class TelemetryChart extends Component {
    _isMounted = false

    state = {
        chartData: {
            chart: {
                type: 'spline'
            },
            colors: [
                "#0f515e"
            ],
            title: {
                text: 'Message Activity'
            },
            yAxis: {
                title: {
                    text: 'Messages'
                }
            },
            xAxis: {
                categories: []
            },
            series: [
                {
                    name: 'Messages',
                    data: []
                }
            ]
        },
        telemData: [],
        loading: true
    }

    timer = null

    requestData = () => {
        this.props.loading(true)
        if (this._isMounted) {
            this.setState({
                loading: true
            })
        }

        let urlParams = UrlParamHelper.getParams(this.props.searchQueryParams)

        Search.get(urlParams, this.props.currentApp, this.props.offsetLimit, this.props.currentUser).then(res => {
            // Format the return data for the chart
            let combindData = [...res, ...this.state.telemData]
            const uniqueArray = combindData.filter((thing, index) => {
                const _thing = JSON.stringify(thing)
                return index === combindData.findIndex(obj => {
                    return JSON.stringify(obj) === _thing
                })
            })

            let chartData = TelemetryDataHelper.prepChartData(uniqueArray)
            let chartObj = this.state.chartData

            // chartObj.xAxis.categories = [...chartObj.xAxis.categories, ...chartData.categories]
            // chartObj.series[0].data = [...chartObj.series[0].data, ...chartData.seriesData]

            chartObj.xAxis.categories = chartData.categories
            chartObj.series[0].data = chartData.seriesData
            
            // Set state
            if (this._isMounted) {
                this.setState({
                    telemData: uniqueArray,
                    chartData: {
                        chart: {
                            type: 'spline'
                        },
                        colors: [
                            "#0f515e"
                        ],
                        title: {
                            text: 'Message Activity'
                        },
                        yAxis: {
                            title: {
                                text: 'Messages'
                            }
                        },
                        series : [
                            { data: chartData.seriesData, name: "Messages" }
                        ],
                        xAxis: {
                            categories: chartData.categories
                        }
                    },
                    loading: false
                })
            }

            this.props.loading(false)
            
        }).catch(e => {
            console.log("[TelemetryChart - SEARCH ERR]: ", e)
        })
    }


    componentDidMount = () => {
        this._isMounted = true
        this.props.loading(true)
        let randNum = Math.floor(Math.random() * 1200) + 500
        if (this.props.polling) {
            let pollTime = this.props.pollingTimer + randNum || 60000
            this.timer = setInterval(()=> this.requestData(), pollTime)
        }
        setTimeout(() => {this.requestData()}, randNum)
        
    }

    componentWillUnmount = () => {
        this._isMounted = false
        clearInterval(this.timer)
        this.timer = null
    }

    render() {
        const { chartData } = this.state
        let chartDatalength = chartData.series[0].data.length || 0
        return (
            <>
                {chartDatalength > 0 ? <HighchartsReact highcharts={Highcharts} options={chartData} /> : 
                    <>
                        {this.state.loading ? <p>Loading new data...</p> : <p>No recent activity reported to Echolo IoT...</p>}
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentUser: (user) => dispatch({ type: Actions.SET_CURRENT_USER, payload: user })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(TelemetryChart)
