import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { flatten } from 'flat'

const filterRow = (key, val, showTelemetryLink) => {
	let row = ''
	switch(key) {
	case 'fields.nodeId':
		row = (
			<>
				<Link to={showTelemetryLink ? '/telemetry?fields.nodeId=' + val : '/device/' + val}>
					<FontAwesomeIcon icon={showTelemetryLink ? 'terminal' : 'microchip'} /> {val}
				</Link> (<Link to={'/device/' + val}><FontAwesomeIcon icon="external-link" /> View</Link>)
			</>
		)
		break
	case 'fields.packet.hubId':
		row = (
			<>
				<Link to={showTelemetryLink ? '/telemetry?fields.packet.hubId=' + val : '/hubs/' + val}>
					<FontAwesomeIcon icon={showTelemetryLink ? 'terminal' : 'router'} /> {val}
				</Link> (<Link to={'/hubs/' + val}><FontAwesomeIcon icon="external-link" /> View</Link>)
			</>
		)
		break
	default:
		row = val
	}
	return row
}

const TelemetryRow = (props) => {
	let tableRow = []
	if (props.rowData) {
        
		// We Flatten data here to make it easy to read in the table
		let flattenData = flatten(props.rowData)
		let d = Object.keys(flattenData)

		tableRow = d.map((key, i) => {
			return (
				<tr key={i}>
					{flattenData[key] ? 
						<>
							<th scope="row">{key}</th>
							<td>{filterRow(key, flattenData[key], props.currentApp.telemetry_flag)}</td>
						</>
						: null}
				</tr>
			)
		})
	}

	return (
		<>
			{tableRow}
		</>
	)
}

export default TelemetryRow
