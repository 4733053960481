import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import * as Actions from '../../store/actions'

import LoadingSpinner from '../../Components/Shared/LoadingSpinner'
import SectionHeader from '../../Components/Shared/SectionHeader'
import DevicePacket from '../../Components/Devices/DevicePacket'
import TelemetryChart from '../../Components/Telemetry/TelemetryChart'
import TelemetryList from '../../Components/Telemetry/TelemetryList'

import * as DateHelper from '../../Helpers/DateHelper'
import * as DeviceHelper from '../../Helpers/DeviceHelper'

import * as Devices from '../../store/Api/Echolo/Device'

import { Application } from '../../Interfaces/application.interface'
import { User } from '../../Interfaces/user.interface'
import { Device } from '../../store/Api/Echolo/interfaces/device.interface'

type DeviceShowState = {
    device?: Device,
    deviceLoading: boolean,
    chartLoading: boolean,
    telemActivityTableLoading: boolean
    lastProfileLoading: boolean,
    errorCount: number
}

class DeviceShow extends React.Component<{
    currentApp: Application,
    currentUser: User,
    match: any,
    setSelectedDevice: any
}, DeviceShowState> {

    state: DeviceShowState = {
        deviceLoading: true,
        chartLoading: false,
        telemActivityTableLoading: false,
        lastProfileLoading: true,
        errorCount: 0
    }

    timer: ReturnType<typeof setInterval> | null = null
    requestDevice = () => {
        Devices.get(this.props.currentApp, this.props.match.params.deviceId, this.props.currentUser).then(data => {
            if (!('error' in data)) {
                this.setState({
                    device: data,
                    deviceLoading: false
                })
                this.props.setSelectedDevice(data)
            } else {
                console.error(data)
                
                // Error
                let newErrorCount = this.state.errorCount
                this.setState({
                    errorCount: newErrorCount++
                })

                if (newErrorCount < 2) {
                    setTimeout(() => this.requestDevice(), 900)
                }
            }
        })
    }

    componentDidMount() {
        // Load Device Info
        // this.props.match.params.deviceId
        this.timer = setInterval(()=> this.requestDevice(), 160000)
        setTimeout(() => this.requestDevice(), 900)
    }

    componentWillUnmount = () => {
        clearInterval(this.timer as ReturnType<typeof setInterval>)
        this.timer = null
        this.props.setSelectedDevice({})
    }
    
    chartLoadingHandler = (status) => {
        this.setState({
            chartLoading: status
        })
    }

    refreshLoadingHandler = (status) => {
        this.setState({
            telemActivityTableLoading: status
        })
    }

    refreshProfileHandler = (status) => {
        this.setState({
            lastProfileLoading: status
        })
    }

    render() {
        let breadcrumb = [{name: 'Hubs', path: 'hubs'}, 
            {name: this.state.device?.seenAt, path: 'hubs/' + this.state.device?.seenAt},
            {name: this.props.match.params.deviceId, path: 'device/' + this.props.match.params.deviceId}]
        
        return (
            <div>
                <SectionHeader breadcrumb={breadcrumb} icon="microchip" title={this.props.match.params.deviceId}></SectionHeader>
                <div className="row">
                    <div className="col-8">
                        <section className="DeviceShowInfoBox">
                            {this.state.deviceLoading ? <LoadingSpinner /> :
                                <>
                                <h4>Device: {this.state.device?.name}</h4>
                                <p>Device ID: <strong>{this.state.device?.nodeId}</strong></p>
                                <p>Seen at Hub ID: <strong><Link to={"/hubs/" + this.state.device?.seenAt}>{this.state.device?.seenAt}</Link></strong></p>
                                </>
                            }
                        </section>
                    </div>

                    <div className="col-4">
                        <section className={DeviceHelper.status(this.state.device?.lastSeenDate) ? "DeviceShowOnlineOfflineBox online" : "DeviceShowOnlineOfflineBox offline"}>
                            {this.state.deviceLoading ? 
                                <>
                                    <LoadingSpinner />
                                </> :  
                                <>
                                    <p><small>{DateHelper.formatDate(this.state.device?.lastSeenDate)}</small></p>
                                    <h4>
                                        { DeviceHelper.status(this.state.device?.lastSeenDate) ?
                                            <><FontAwesomeIcon icon="signal" /> Online</> :
                                            <><FontAwesomeIcon icon="signal-alt-slash" /> Offline</>
                                        }
                                    </h4>
                            </>}
                        </section>
                    </div>

                </div>

                <div className="row">
                    <div className="col-6">
                        <section className="DeviceShowProfilePacket">
                            <h4>Latest Packet Data</h4>
                            {this.state.deviceLoading ? <LoadingSpinner /> :
                                <>
                                    {this.state.device?.profilePacket ?
                                        <>
                                            <p>Device Profile Type: {this.state.device.nodeType}</p>
                                            <table className="table table-hover table-sm">
                                                <tbody>
                                                    <DevicePacket packetData={this.state.device.profilePacket} />
                                                </tbody>
                                            </table> 
                                            <p><strong>Raw Data:</strong> {this.state.device.packet}</p>

                                            <hr />
                                            <h5>Latest Profile Status:</h5>
                                            <TelemetryList tableStyleIdBase="profileTable_" polling={true} pollingTimer={121500} loadingHandler={this.refreshProfileHandler} offsetLimit="1" currentApp={this.props.currentApp} currentUser={this.props.currentUser} searchQueryParams={"?fields.nodeId=" + this.props.match.params.deviceId + "&fields.node_status=profiled"} />
                                        </>
                                    :
                                        <p>{this.state.device?.packet}</p>
                                    }
                                </>
                            }
                        </section>
                    </div>

                    <div className="col-6">
                        <section className="DeviceShowChart">
                            <h4>Activity {this.state.chartLoading ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  : null}</h4>
                            <div className="DeviceShowChartObject">
                                <TelemetryChart loading={this.chartLoadingHandler} searchQueryParams={"?fields.nodeId=" + this.props.match.params.deviceId} currentApp={this.props.currentApp} offsetLimit="50" polling={true} pollingTimer={120000} />
                            </div>
                        </section>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        <section>
                            <h4>Live Telemetry {this.state.telemActivityTableLoading ? <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>  : null}</h4>
                            <TelemetryList polling={true} pollingTimer={122000} loadingHandler={this.refreshLoadingHandler} offsetLimit="10" currentApp={this.props.currentApp} currentUser={this.props.currentUser} searchQueryParams={"?fields.nodeId=" + this.props.match.params.deviceId} />
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        hubs: state.hub.hubs
    }
}

const mapDispactToProps = dispatch => {
    return {
        setSelectedDevice: (device) => dispatch({ type: Actions.SET_SELECTED_DEVICE, payload: device })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(DeviceShow)
