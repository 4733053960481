import React from 'react'
import { ApmRoute } from '@elastic/apm-rum-react'

const AppRoute = ({ component: Component, layout: Layout, menu: Menu, ...rest }) => {

	let route = (
		<ApmRoute
			{...rest}
			render = {props => (
				<Layout>
					<Component {...props}>{props.children}</Component>
				</Layout>
			)}    
		/> 
	)
	if (Menu) {
		route = (
			<ApmRoute
				{...rest}
				render = {props => (
					<Layout menuLayout={<Menu />}>
						<Component {...props}>{props.children}</Component>
					</Layout>
				)}    
			/> 
		)
	}

	return (
		<>{route}</> 
	)
}

export default AppRoute
