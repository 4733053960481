import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import * as Application from '../../store/Api/Echolo/Application'

import Applications from '../../pages/Applications/Applications'

type ApplicationCreateProps = {
    appId: string
}

const ApplicationCreate = (props) => {
    const appValues = {
        appId: ''
    }

    const { register, handleSubmit, errors } =
        useForm({reValidateMode: "onBlur"})

    const [newApp, setNewApp] = useState(appValues)
    const [showOtherApps, setShowOtherApps] = useState(false)
    const [newAppData, setNewAppData] = useState(null)

    const [showAddError, setShowAddError] = useState(false);
    const handleNewApp = async (appData) => {
        try {
            const addedApplication = await Application.activate(props.user, appData)
            setNewAppData(addedApplication)
            setShowAddError(false)
            setNewApp({ appId: '' })
        } catch (error) {
            setShowAddError(true)
        }
    }

    const handleHasApps = (apps) => {
        if (apps.length > 0) setShowOtherApps(true)
    }

    return (
        <div className="col-6">
            <section className={''}>
                <div className={'alert alert-info'}>
                    <h4 className={'alert-heading'}><FontAwesomeIcon icon="plus" /> Add a new Application</h4>
                </div>

                <h5>Active Applications on your account:</h5>
                <Applications addedApp={newAppData} hasApps={handleHasApps} addedClass={'cursor-pointer onDashboard'} />
                <hr />

                <p>As part of your account setup, the Echolo support team would have provided you with your new application ID (also known as an 'appId'). If you haven't received this information, please reach out to our support team via email at <a href={'mailto:support@echolo.io'}>support@echolo.io</a></p>

                {showAddError ?
                    <div className="alert alert-danger" role="alert">
                        <p><FontAwesomeIcon icon={'exclamation-triangle'} /> <strong>Failed to add new Application</strong></p>
                        <p>Sorry, the Application ID you entered does not match an active application. Try again or contact support for more help.</p>
                    </div>
                : null}

                <form onSubmit={handleSubmit(handleNewApp)}>
                    <div className="form-group">
                        {errors.appId && <p className="text-danger">Application ID is required when adding a new application.</p>}
                        <input value={newApp.appId}
                               name="appId"
                               onChange={e => setNewApp({ ...newApp, appId: e.target.value })}
                               type={'text'}
                               placeholder="Application ID"
                               className="form-control form-control-lg" id="appId"
                               ref={register({ required: true })} />
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <button type="submit" className="btn btn-outline-primary btn-lg float-right">Save <FontAwesomeIcon icon="save" /></button>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    )
}

export default ApplicationCreate