import React, { Component } from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LoadingSpinner from '../Shared/LoadingSpinner'
import TelemetryRow from './TelemetryRow'

import * as Search from '../../store/Api/Echolo/Search'
import * as DateHelper from '../../Helpers/DateHelper'
import * as UrlParamHelper from '../../Helpers/UrlParams'

/*
    [searchQueryParams] => String("?something=1234")
    [offsetLimit] => String(Int)
    [currentUser]
    [currentApp]
    [polling] => Boolean
    [pollingTimer] => Int
    [loadingHandler] => Host Function
*/
class TelemetryList extends Component {
    _isMounted = false
    
    state = {
        telemData: [],
        tableIds: [],
        loading: true,
        tableStyleIdBase: "telem_"
    }

    timer = null

    requestData = () => {
        this.props.loadingHandler(true)
     
        let urlParams = UrlParamHelper.getParams(this.props.searchQueryParams)
        
        Search.get(urlParams, this.props.currentApp, this.props.offsetLimit, this.props.currentUser).then(res => {
            // Format the return data for the chart
            let combindData = [...res, ...this.state.telemData]

            const uniqueArray = combindData.filter((thing, index) => {
                const _thing = JSON.stringify(thing)
                return index === combindData.findIndex(obj => {
                    return JSON.stringify(obj) === _thing
                })
            })

            // Set state
            if (this._isMounted) {
                if (!uniqueArray[0]?.error) {
                    this.setState({
                        telemData: uniqueArray,
                        loading: false
                    })
                }
            }
            this.props.loadingHandler(false)
        }).catch(e => {
            console.log("[TelemetryLIST - SEARCH ERR]: ", e)
        })
    }

    clearPollingTimer = () => {
        clearInterval(this.timer)
        this.timer = null
    }

    startPollingTimer = (randTime) => {
        let pollTime = this.props.pollingTimer + randTime || 60000
        this.timer = setInterval(()=> this.requestData(), pollTime)
    }

    componentDidUpdate = (prevProps) => {

        if (prevProps.searchQueryParams !== this.props.searchQueryParams) {
            
            this.clearPollingTimer()

            if (this._isMounted) {
                this.setState({
                    telemData: []
                })
            }

            let randNum = Math.floor(Math.random() * 1900) + 1200
            if (this.props.polling) {
                this.startPollingTimer(randNum)
            }
            setTimeout(() => {this.requestData()}, randNum)
        }
    }
    
    componentWillUnmount = () => {
        this._isMounted = false
        this.clearPollingTimer()
    }

    componentDidMount() {
        this._isMounted = true
        this.props.loadingHandler(true)
        let randNum = Math.floor(Math.random() * 1900) + 900
        if (this.props.polling) {
            this.startPollingTimer(randNum)
        }
        setTimeout(() => {this.requestData()}, randNum)
        this.setState({
            tableStyleIdBase: this.props.tableStyleIdBase || "baseTable_"
        })
    }

    showTableHandler = (e, id) => {
        $("#tableData_" + this.state.tableStyleIdBase + id).slideToggle(1000)
        $("#"+ this.state.tableStyleIdBase + id).toggleClass('TelemetryListTitleSelected')

        $("#icon_right_" + this.state.tableStyleIdBase + id).toggle()
        $("#icon_down_" + this.state.tableStyleIdBase + id).toggle()
    }

    render() {

        let telemData = null
        if (this.state.telemData.length > 0) {

            telemData = this.state.telemData.map((item, key) => {
                return (
                    <div key={key}>
                        <div id={this.state.tableStyleIdBase + key} className="row TelemetryListTitle" onClick={e => this.showTableHandler(e, key)}>
                            <div className="col-4">
                                <p>
                                    <span className="icon_toggle" id={"icon_right_" + this.state.tableStyleIdBase + key}><FontAwesomeIcon icon="chevron-right" /></span>
                                    <span className="icon_toggle icon_down" id={"icon_down_" + this.state.tableStyleIdBase + key}><FontAwesomeIcon icon="chevron-down" /></span> 
                                    {DateHelper.formatDate(item["@timestamp"])}</p>
                            </div>

                            <div className="col-3">
                                <p>{item.message}</p>
                            </div>

                            <div className="col-2">
                                <p>{item.fields.nodeId}</p>
                            </div>

                            <div className="col-3">
                                <p>{item.fields.node_status}</p>
                            </div>
                        </div>
                        <div id={"tableData_" + this.state.tableStyleIdBase + key} className="row hideTableData TelemetryListTableData">
                            <div className="col-12">
                                <table className="table table-hover table-sm">
                                    <tbody>
                                        <TelemetryRow currentApp={this.props.currentApp} rowData={item} />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            })
    
        }

        return (
            <div>
                {this.state.telemData.length > 0 ? 
                    <>
                        <div className="row TelemetryTableHeader">
                            <div className="col-4">
                                <p><strong>Timestamp</strong></p>
                            </div>
                            <div className="col-3">
                                <p><strong>Message</strong></p>
                            </div>
                            <div className="col-2">
                                <p><strong>Node ID</strong></p>
                            </div>
                            <div className="col-3">
                                <p><strong>Stage (node_status)</strong></p>
                            </div>
                        </div>
                        {this.state.loading ? <div className="text-center"><LoadingSpinner /></div> :
                            telemData
                        } 
                    </> :
                    <p>No recent activity reported to Echolo IoT...</p>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        hubs: state.hub.hubs
    }
}


export default connect(mapStateToProps)(TelemetryList)
