import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import * as Actions from '../store/actions'

import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { Auth } from 'aws-amplify'

import LoginForm from '../Components/Login/LoginForm'


class Login extends Component {

    state = {
        showSetPassword: false,
        resetUser: null,
        showAlert: null
    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    

    // TODO: Finish Login
    // *0) Validate forms
    // *1) Send to API for validation
    // *2) Save data to store
    // *3) Save data to Cookie
    // *4) Move API request to Lib
    // 5) Auto-Login if user is stored -> Moved to app

    loginHandler = async (userData) => {
        try {
            const user = await Auth.signIn(userData.email, userData.password)
            const { attributes } = user
            let configureUser = {
                first_name: attributes.name,
                last_name: attributes.family_name,
                email: attributes.email,
                authentication_token: attributes["custom:el-APItoken"],
                rtls_flag: attributes["custom:rtls_flag"],
                telemetry_flag: attributes["custom:telemetry_flag"],
                xapi_key: attributes["custom:xapi_key"],
                userId: attributes.sub
            }
            this.props.setCurrentUser(configureUser)
            this.props.cookies.set('currentUser', configureUser, { path: '/' })
        } catch (error) {
            // If user was converted OR invited they will have to set a password.
            if (error.code === "PasswordResetRequiredException") {
                this.setState({
                    showSetPassword: true,
                    resetUser: userData.email
                })
            } else {
                console.log(error)
                this.setState({
                    showAlert: <div className="alert alert-danger" role="alert">
                                    I am sorry! There was an issue. {error.message}
                                </div>
                })
            }
        }
    }

    render() {
        
        return (
            <div>
                {this.state.showAlert}
                {this.state.showSetPassword ? 
                    <Redirect to={"/forgotpassword?user=" + this.state.resetUser} /> :
                    <>
                        <h3 className="text-center text-dark">Account Login</h3>
                        <p className="text-center text-subtext">Enter your email address and password.</p>
                        <LoginForm className="LoginForm" login={this.loginHandler} />
                    </>
                }

                <p className="text-size-medium">Did you <Link to="/forgotpassword">forget your password</Link>? It happends, reset it here.</p>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentUser: (user) => dispatch({ type: Actions.SET_CURRENT_USER, payload: user })
    }
}

export default withCookies(connect(mapStateToProps, mapDispactToProps)(Login))
