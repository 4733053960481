import React, { useEffect, useState } from "react"
import ProfileTableRow from './ProfileTableRow'
import * as _ from 'lodash'

const ProfileTable = ({ profiles }) => {
    return (
        <table className="table table-hover">
            <thead>
            <tr>
                <th scope="col">Service ID</th>
                <th scope="col">Integration</th>
                <th scope="col">Delivery Methods</th>
                <th scope="col">Configured RSSI</th>
            </tr>
            </thead>
            <tbody>
            {
                !_.isEmpty(profiles) ?
                    <>
                        {profiles.map((row) => (
                            <ProfileTableRow key={row.serviceId} row={row} />
                        ))}
                    </>
                    : null
            }
            </tbody>
        </table>
    )
}

export default ProfileTable
