import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as Actions from '../../store/actions'

import { DevicesAll } from '../../store/Api/Echolo/Device'

import LoadingSpinner from '../../Components/Shared/LoadingSpinner'
import SectionHeader from '../../Components/Shared/SectionHeader'

import DevicesSubMenu from '../../Layouts/menus/DevicesSubMenu'

import DeviceList from '../../Components/Devices/DeviceList'

import { Application, ApplicationNumbers } from '../../Interfaces/application.interface'
import { User } from '../../Interfaces/user.interface'
import { Device } from '../../Interfaces/device.interface'
type DevicesState = {
    devices: Device[],
    loading: boolean,
    deviceListType: string,
    onlineNumbers: number,
    offlineNumbers: number,
}
class Devices extends React.Component<{
    currentApp: Application,
    currentUser: User,
    location: any,
    setApplicationNumbers: any,
    accountNumbers: ApplicationNumbers}, DevicesState> {
    state: DevicesState = {
        devices: [],
        loading: false,
        deviceListType: "online",
        onlineNumbers: 0,
        offlineNumbers: 0

    }

    _isMounted = true
    timer = null

    getDevices = () => {
        if (this._isMounted) {
            this.setState({
                loading: true
            })
        }
        DevicesAll(this.props.currentApp, this.props.currentUser).then(devices => {
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    devices: devices
                })
            }
        }).catch(e => {
            console.error(e)
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.location.search !== prevProps.location.search) {
            if (this.props.location.search.includes('?offline=true')) {
                this.setState({
                    deviceListType: "offline"
                })
            } else {
                this.setState({
                    deviceListType: "online"
                })
            }
        }
    }

    componentDidMount = () => {
        if (this.props.location.search.includes('?offline=true')) {
            this.setState({
                deviceListType: "offline"
            })
        }

        this._isMounted = true
        this.getDevices()
    }

    componentWillUnmount = () => {
        this._isMounted = false
    }

    deviceCountHandler = (online, offline) => {
        let accountNumbers = this.props.accountNumbers
        let newAccountNumbers = {}
        this.setState({
            onlineNumbers: online.length,
            offlineNumbers: offline.length
        })
        let deviceNumbers = [...online, ...offline]
        newAccountNumbers = { devices: deviceNumbers }
        this.props.setApplicationNumbers({...accountNumbers, ...newAccountNumbers})
    }

    render() {
        let breadcrumb = [{name: 'Devices', path: 'devices'}]
        return (
            <>
                <SectionHeader breadcrumb={breadcrumb} icon="microchip" title="Devices">
                    <div className="row">
                        <div className="col-4">
                            
                        </div>
                        <div className="col-8 text-right">
                            
                        </div>
                    </div>
                </SectionHeader>

                <DevicesSubMenu 
                    deviceCounts={{online: this.state.onlineNumbers, offline: this.state.offlineNumbers}} 
                    location={this.props.location.search} />

                <div className="row">
                    <div className="col-12">
                        <section className="deviceList">
                            <h4>{_.capitalize(this.state.deviceListType)} Devices</h4>
                            {this.state.loading ? 
                            <>
                                <LoadingSpinner />
                            </> :  
                            <>
                                <DeviceList counts={this.deviceCountHandler} listType={this.state.deviceListType} devices={this.state.devices} />
                            </>}
                        </section>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        hubs: state.hub.hubs,
        accountNumbers: state.app.applicationNumbers
    }
}

const mapDispactToProps = dispatch => {
    return {
        setApplicationNumbers: (data) => dispatch({ type: Actions.SET_APPLICATION_NUMBERS, payload: data })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(Devices)
