import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TelemetrySearchForm = (props) => {
	const [searchInput, setSearchInput] = useState('')

	useEffect(() => {
		if (props.initSearch && props.initSearch[0]) {
			setSearchInput(JSON.stringify(props.initSearch[0]).replace(/{/g, '').replace(/}/g, '').replace(/"/g, ''))
		} else {
			setSearchInput('')
		}
	}, [props.initSearch])

	const resetSearchInput = () => {
		setSearchInput('')
	}

	const SearchInputSetter = (e, helper) => {
		setSearchInput(helper)
	}

	return (

		<form onSubmit={e => props.submitHandler(e, searchInput)}>
			<div className="row">
				<div className="col-12"></div>
			</div>
			<div className="row">
				<div className="col-12 TelemetrySearchForm-helpers">
					<p><strong>Supported Tags:</strong></p>
					<span onClick={e => SearchInputSetter(e, 'fields.nodeId:')} className="badge badge-info helper-badge"><FontAwesomeIcon icon="tag" /> fields.nodeId</span>
					<span onClick={e => SearchInputSetter(e, 'fields.packet.hubId:')} className="badge badge-info helper-badge"><FontAwesomeIcon icon="tag" /> fields.packet.hubId</span>
					<span onClick={e => SearchInputSetter(e, 'fields.profile_type:')} className="badge badge-info helper-badge"><FontAwesomeIcon icon="tag" /> fields.profile_type</span>
					<span onClick={e => SearchInputSetter(e, 'fields.node_status:')} className="badge badge-info helper-badge"><FontAwesomeIcon icon="tag" /> fields.node_status</span>
				</div>
			</div>

			<div className="row TelemetrySearchForm">
				<div className="col-12">
					<div className="form-group">

						<div className="input-group mb-2">
							<div className="input-group-prepend">
								<div className="input-group-text"><FontAwesomeIcon icon="search" /></div>
							</div>
							<input onChange={e => setSearchInput(e.target.value)} className="form-control" type="text" value={searchInput} name="s" />
						</div>
					</div>
					<div className="row">
						<div className="col-8">
							<button onClick={e => resetSearchInput(e)} className="btn btn-outline-secondary btn-sm"><FontAwesomeIcon icon="backspace" /> Reset</button>
						</div>

						<div className="col-4 text-right">
							<button type="submit" className="btn btn-primary btn-sm"><FontAwesomeIcon icon="terminal" /> Search</button>
						</div>
					</div>
				</div>
			</div>
            
		</form>
	)
}

export default TelemetrySearchForm
