import axios from 'axios'
import { TokenEcholo } from './token.echolo'
import { Hub } from './interfaces/hub.interface'
import { Device } from './interfaces/device.interface'
import {Application} from "./interfaces/application.interface";
import {User} from "../../../Interfaces/user.interface";


export let all = async (app, user): Promise<Hub[]> => {
	const tokenData = new TokenEcholo(user)
	const updatedToken = await tokenData.getToken(app)
	return new Promise((resolve, reject) => {
		axios.get('/hubs/', tokenData.tokenHeaders({ ...app, token: updatedToken })).then(res => {
			resolve(res.data)
		}).catch(e => {
			reject(e)
		})
	})
}

export const get = async (app, id, user): Promise<Hub> => {
	const tokenData = new TokenEcholo(user)
	const updatedToken = await tokenData.getToken(app)
	return new Promise((resolve, reject) => {
		axios.get('/hubs/' + id, tokenData.tokenHeaders({ ...app, token: updatedToken })).then(res => {
			if (res.data.error) reject(res.data.error)
			resolve(res.data)
		}).catch(e => {
			reject(e)
		})
	})
}

export const devices = async (app: Application, id: string, user: User): Promise<Device[]> => {
	const tokenData = new TokenEcholo(user)
	const updatedToken = await tokenData.getToken(app)
	return new Promise(async (resolve, reject) => {
		try {
			const devices = await axios.get('/hubs/' + id + '/devices', tokenData.tokenHeaders({ ...app, token: updatedToken }))
			resolve(devices.data)
		} catch (error) {
			reject(error)
		}
	})
}

export const update = async (app, id, user, data) => {
	const tokenData = new TokenEcholo(user)
	const updatedToken = await tokenData.getToken(app)
	return new Promise((resolve, reject) => {
		axios.put('/hubs/' + id, data, tokenData.tokenHeaders({ ...app, token: updatedToken })).then(res => {
			if (res.data.error) reject(res.data.error)
			resolve(res.data)
		}).catch(e => {
			reject(e)
		})
	})
}

export const reboot = (app, id, user) => {
	const tokenData = new TokenEcholo(user)
	return new Promise((resolve, reject) => {
		axios.get('/applications/' + app.app_identifier + '/hubs/' + id + '/reboot', tokenData.tokenHeaders(app)).then(res => {
			resolve(res.data)
		}).catch(e => {
			reject(e)
		})
	})
}

export const toggle_leds = (app, id, status, user) => {
	const tokenData = new TokenEcholo(user)
	return new Promise((resolve, reject) => {
		axios.get('/applications/' + app.app_identifier + '/hubs/' + id + '/setled/' + status, tokenData.tokenHeaders(app)).then(res => {
			resolve(res.data)
		}).catch(e => {
			reject(e)
		})
	})
}

