import React from 'react'

import BrokersListItem from './BrokersListItem'

const BrokersList = (props) => {

	let brokers = props.brokers.map((broker, key) => {
		return (
			<BrokersListItem key={broker.brokerId} broker={broker} />
		)
	})

	return (
		<div>
			{brokers}
		</div>
	)
}

export default BrokersList
