import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'

import * as Hub from '../../store/Api/Echolo/Hub'

import HubList from '../../Components/Hubs/HubList'
import SectionHeader from '../../Components/Shared/SectionHeader'
import HubsSubMenu from '../../Layouts/menus/HubsSubMenu'

class Hubs extends Component {
    state = {
        hubsLoading: true,
        hubCount: 0,
        hubListType: "online",
        hubs: [],
        onlineNumbers: 0,
        offlineNumbers: 0
    }

    timer = null
    _isMounted = true

    requestHubs = () => {
        if (this.props.currentApp) {
            // Request Hubs
            Hub.all(this.props.currentApp, this.props.currentUser).then(data => {
                if (this._isMounted) {
                    this.props.setHubList(data)
                    this.setState({
                        hubsLoading: false,
                        hubs: data
                    })
                }
            })
            
        }
    }

    componentDidMount() {
        this._isMounted = true
        if (this.props.location.search.includes('?offline=true')) {
            this.setState({
                hubListType: "offline"
            })
        }
        this.requestHubs()
        this.timer = setInterval(()=> this.requestHubs(), 120000)
        this.props.setSelectedHub({})
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.location.search !== prevProps.location.search) {
            if (this.props.location.search.includes('?offline=true')) {
                this.setState({
                    hubListType: "offline"
                })
            } else {
                this.setState({
                    hubListType: "online"
                })
            }
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false
        clearInterval(this.timer)
        this.timer = null
    }

    hubCountHandler = (online, offline) => {
        let accountNumbers = this.props.accountNumbers
        let newAccountNumbers = {}
        this.setState({
            onlineNumbers: online.length,
            offlineNumbers: offline.length
        })
        let hubNumbers = [...online, ...offline]
        newAccountNumbers.hubs = hubNumbers
        this.props.setApplicationNumbers({...accountNumbers, ...newAccountNumbers})
    }


    render() {
        return (
            <div>
                <SectionHeader icon="router" title="Hubs"></SectionHeader>
                <HubsSubMenu 
                    hubCounts={{online: this.state.onlineNumbers, offline: this.state.offlineNumbers}} 
                    location={this.props.location.search} />
                <section>
                    {this.props.currentApp ? 
                    <>
                        <HubList counts={this.hubCountHandler} loading={this.state.hubsLoading} hubData={this.state.hubs} listType={this.state.hubListType} />
                    </> : <p>Select an application first.</p>}
                </section>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        currentUser: state.user.currentUser,
        currentApp: state.app.currentApp,
        hubs: state.hub.hubs,
        accountNumbers: state.app.applicationNumbers
    }
}

const mapDispactToProps = dispatch => {
    return {
        setCurrentApp: (apps) => dispatch({ type: Actions.SET_CURRENT_APPLICATION, payload: apps }),
        setHubList: (hubs) => dispatch({ type: Actions.SET_HUB_LIST, payload: hubs }),
        setSelectedHub: (hub) => dispatch({ type: Actions.SET_SELECTED_HUB, payload: hub }),
        setApplicationNumbers: (data) => dispatch({ type: Actions.SET_APPLICATION_NUMBERS, payload: data })
    }
}

export default connect(mapStateToProps, mapDispactToProps)(Hubs)