const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
]
const days = [
	'Sun',
	'Mon',
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat'
]

const addZero = (i) => {
	if (i < 10) {
		i = '0' + i
	}
	return i
}

export const formatDate = (incomingDate) => {
	let d = Date.parse(incomingDate)
	d = new Date(d)

	const year = d.getFullYear()
	const date = d.getDate()

	const monthName = months[d.getMonth()]
	const dayName = days[d.getDay()]

	const hour = d.getHours()
	const mins = addZero(d.getMinutes())
	const seconds = addZero(d.getSeconds())

	return `${dayName}, ${date} ${monthName} ${year} @ ${hour}:${mins}:${seconds}`
}

export const formatDateShort = (incomingDate) => {
	let d = Date.parse(incomingDate)
	d = new Date(d)

	const year = d.getFullYear()

	const monthNum = d.getMonth() + 1
	const dayNum = d.getDay()

	const hour = d.getHours()
	const mins = addZero(d.getMinutes())

	return `${monthNum}/${dayNum}/${year} @ ${hour}:${mins}`
}

export const formatTime = (incomingDate) => {
	let d = Date.parse(incomingDate)
	d = new Date(d)

	const hour = d.getHours()
	const mins = addZero(d.getMinutes())
	const seconds = addZero(d.getSeconds())

	return `${hour}:${mins}:${seconds}`
}

export const currentYear = () => {
	let d = new Date()
	const year = d.getFullYear()
	return `${year}`
}